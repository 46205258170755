/* eslint-disable jsdoc/valid-types */
import {
    AddCircleOutline,
    AirlineSeatFlatOutlined,
    ChurchOutlined,
    DashboardOutlined,
    Inventory2Outlined,
    PeopleOutline,
} from '@mui/icons-material';
import {CustomerListPage} from 'applications/beyondbuddy/settings/pages/customer/CustomerListPage';
import {CustomerPage} from 'applications/beyondbuddy/settings/pages/customer/CustomerPage';
import {PeaceBuddyDashboard} from 'applications/peacebuddy/pages/PeaceBuddyDashboard';
import {PeaceBuddyTileDashboard} from 'applications/peacebuddy/pages/PeaceBuddyTileDashboard';
import {CemeteryListPage} from 'applications/peacebuddy/settings/pages/cemetery/CemeteryListPage';
import {CemeteryPage} from 'applications/peacebuddy/settings/pages/cemetery/CemeteryPage';
import {GraveListPage} from 'applications/peacebuddy/settings/pages/grave/GraveListPage';
import {GravePage} from 'applications/peacebuddy/settings/pages/grave/GravePage';
import {GuideCemeteryPage} from 'applications/peacebuddy/settings/pages/quickguides/GuideCemeteryPage';
import {GuideGravePage} from 'applications/peacebuddy/settings/pages/quickguides/GuideGravePage';
import {GuideGraveCustomerPage} from 'applications/peacebuddy/settings/pages/quickguides/GuideGraveCustomerPage';
import {GuideGraveCustomerSynchronizationPage} from 'applications/peacebuddy/settings/pages/quickguides/GuideGraveCustomerSynchronizationPage';
import {GuideGraveConnectionPage} from 'applications/peacebuddy/settings/pages/quickguides/GuideGraveConnectionPage';
import {DeceasedPersonListPage} from 'applications/peacebuddy/settings/pages/deceasedPerson/DeceasedPersonListPage';
import {DeceasedPersonPage} from 'applications/peacebuddy/settings/pages/deceasedPerson/DeceasedPersonPage';
import {GuideGraveRecordPage} from 'applications/peacebuddy/settings/pages//quickguides/GuideGraveRecordPage';
import {GuideDeceasedPersonPage} from 'applications/peacebuddy/settings/pages//quickguides/GuideDeceasedPersonPage';

/**
 * #### When making changes to the visible menus, the equivalent change should also be made to the menu test
 * @type {import('applications/configuration').ApplicationConfiguration}
 */
const applicationConfiguration = {
    id: 'peacebuddy',
    tileDashboard: <PeaceBuddyTileDashboard />,
    accessConditions: ['PeaceBuddy'],
    name: 'PeaceBuddy',
    order: 3,
    description: 'die simple Friedhofsverwaltung',
    menu: {
        id: 'peacebuddy_menu',
        icon: <ChurchOutlined />,
        label: 'Peace',
        routeId: 'peacebuddy_route',
    },
    route: {
        id: 'peacebuddy_route',
        path: 'peace',
        routes: [
            {
                element: <PeaceBuddyDashboard />,
            },
        ],
    },
    modules: [
        {
            id: 'peacebuddy_settings',
            tileDashboard: <PeaceBuddyTileDashboard />,
            name: 'PeaceBuddy',
            // defaultRouteId: 'peacebuddy_dashboard_route',
            menus: [
                {
                    id: 'peacebuddy_dashboard_menu',
                    icon: <DashboardOutlined />,
                    label: 'Dashboard',
                    routeId: 'peacebuddy_dashboard_route',
                },
                {
                    id: 'peacebuddy_settings_cemeteries_menu',
                    icon: <ChurchOutlined />,
                    label: 'Friedhöfe',
                    routeId: 'peacebuddy_settings_cemeteries_route',
                    enableConditions: ['readCemetery'],
                    menus: [
                        {
                            id: 'peacebuddy_settings_cemetery_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Friedhof anlegen',
                            routeId: 'peacebuddy_settings_cemetery_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createCemetery'],
                        },
                    ],
                },
                {
                    id: 'peacebuddy_settings_graves_menu',
                    icon: <Inventory2Outlined />,
                    label: 'Gräber',
                    routeId: 'peacebuddy_settings_graves_route',
                    enableConditions: ['readGrave'],
                    menus: [
                        {
                            id: 'peacebuddy_settings_grave_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Grab anlegen',
                            routeId: 'peacebuddy_settings_grave_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createCemetery'],
                        },
                    ],
                },
                {
                    id: 'peacebuddy_settings_deceasedPersons_menu',
                    icon: <AirlineSeatFlatOutlined />,
                    label: 'Verstorbene',
                    routeId: 'peacebuddy_settings_deceasedPersons_route',
                    enableConditions: ['readDeceasedPerson'],
                    menus: [
                        {
                            id: 'peacebuddy_settings_deceasedPerson_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Verstorbenen anlegen',
                            routeId: 'peacebuddy_settings_deceasedPerson_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createDeceasedPerson'],
                        },
                    ],
                },
                {
                    id: 'peacebuddy_settings_customers_menu',
                    icon: <PeopleOutline />,
                    label: 'Nutzungsberechtigte',
                    routeId: 'peacebuddy_settings_customers_route',
                    enableConditions: ['readCustomer'],
                    menus: [
                        {
                            id: 'peacebuddy_settings_customer_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Nutzungsberechtigten anlegen',
                            routeId: 'peacebuddy_settings_customer_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createCustomer'],
                        },
                    ],
                },
            ],
            routes: [
                {
                    id: 'peacebuddy_dashboard_route',
                    path: 'dashboard',
                    element: <PeaceBuddyDashboard />,
                },
                {
                    id: 'peacebuddy_settings_customers_route',
                    path: 'customer',
                    accessConditions: ['readCustomer'],
                    routes: [
                        {
                            element: <CustomerListPage
                                customerRouteId="peacebuddy_settings_customer_route"
                                initialFilters={{allowedApplications: ['PeaceBuddy']}}
                                title="Nutzungsberechtigte"
                            />,
                        },
                        {
                            id: 'peacebuddy_settings_customer_route',
                            path: ':id',
                            element: <CustomerPage initialData={{allowedApplications: ['PeaceBuddy']}} />,
                        },
                    ],
                },
                {
                    id: 'peacebuddy_settings_cemeteries_route',
                    path: 'cemetery',
                    accessConditions: ['readCemetery'],
                    routes: [
                        {
                            element: <CemeteryListPage />,
                        },
                        {
                            id: 'peacebuddy_settings_cemetery_route',
                            path: ':id',
                            element: <CemeteryPage />,
                        },
                    ],
                }, {
                    id: 'peacebuddy_settings_graves_route',
                    path: 'grave',
                    accessConditions: ['readGrave'],
                    routes: [
                        {
                            element: <GraveListPage />,
                        },
                        {
                            id: 'peacebuddy_settings_grave_route',
                            path: ':id',
                            element: <GravePage />,
                            routes: [
                                {
                                    id: 'peacebuddy_settings_grave_sub_route',
                                    path: ':subId',
                                    element: <GravePage />,
                                },
                            ],
                        },
                    ],
                }, {
                    id: 'peacebuddy_settings_deceasedPersons_route',
                    path: 'deceasedPerson',
                    accessConditions: ['readDeceasedPerson'],
                    routes: [
                        {
                            element: <DeceasedPersonListPage />,
                        },
                        {
                            id: 'peacebuddy_settings_deceasedPerson_route',
                            path: ':id',
                            element: <DeceasedPersonPage />,
                            routes: [
                                {
                                    id: 'peacebuddy_settings_deceasedPerson_sub_route',
                                    path: ':subId',
                                    element: <DeceasedPersonPage />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    guides: [
        {
            id: 'peacebuddy_general_cemetery',
            title: 'Friedhof',
            content: <GuideCemeteryPage />,
        },
        {
            id: 'peacebuddy_general_grave',
            title: 'Grab',
            content: <GuideGravePage />,
        },
        {
            id: 'peacebuddy_general_graveRecord',
            title: 'GrabEintrag',
            content: <GuideGraveRecordPage />,
        },
        {
            id: 'peacebuddy_general_deceasedPerson',
            title: 'Verstorbene Person',
            content: <GuideDeceasedPersonPage />,
        },
        {
            id: 'peacebuddy_general_grave_authorized_customers',
            title: 'Nutzungsberechtigte',
            content: <GuideGraveCustomerPage />,
        },
        {
            id: 'peacebuddy_general_grave_authorized_customers_synchronization',
            title: 'Nutzungsberechtigte synchronisieren',
            content: <GuideGraveCustomerSynchronizationPage />,
        },
        {
            id: 'peacebuddy_general_grave_connections',
            title: 'Grabverknüpfungen',
            content: <GuideGraveConnectionPage />,
        },
    ],
};

/**
 * @typedef {import('./types').PermissionGroupPeaceBuddy} PermissionGroupPeaceBuddy
 * @typedef {import('applications/beyondbuddy/types').PermissionGroupBeyondBuddy} PermissionGroupBeyondBuddy
 * @typedef {import('applications/configuration').Permission} Permission
 * @typedef {import('applications/configuration').OperationName} OperationName
 * @typedef {import('./types').UnmanagedObjectPeaceBuddy} UnmanagedObjectPeaceBuddy
 * @typedef {`${OperationName}${UnmanagedObjectPeaceBuddy}`} UnmanagedOperation
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('applications/configuration').ManagedObject} ManagedObject
 */

/** @type {Record<PermissionGroupPeaceBuddy, Permission[]>} */
const permissionGroupDefinition = {
    extendedReadPeaceBuddy: [
        'readGraveRecord',
    ],
    administrateDeceasedPersonGroup:
    [
        'read', 'update', 'delete',
        'readPermission', 'updatePermission',
        'readAttributes', 'updateAttributes',
        'createGraveRecord', 'readGraveRecord', 'updateGraveRecord', 'deleteGraveRecord',
        'readProtocolEntry', 'createProtocolEntry', 'updateProtocolEntry', 'deleteProtocolEntry',
    ],
    administrateGraveGroup:
    [
        'read', 'update', 'delete',
        'readPermission', 'updatePermission',
        'readAttributes', 'updateAttributes',
        'createGraveRecord', 'readGraveRecord', 'updateGraveRecord', 'deleteGraveRecord',
        'readProtocolEntry', 'createProtocolEntry', 'updateProtocolEntry', 'deleteProtocolEntry',
    ],

};

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = () => ({});

// /** @type {Record<import('./types').ManagedObjectPeaceBuddy, string>} */
// const entityLabels = {
//     Cemetery: 'Friedhof',
//     Grave: 'Grab',
//     // GraveTariff: 'Tarif',
//     // DeceasedPerson: 'Verstorbener',
// };

/** @type {import('applications/configuration').ManagedObject[]} */
const managedEntityKeys = ['Cemetery', 'Grave', 'DeceasedPerson'];

/** @type {Record<UnmanagedOperation|Exclude<PermissionGroupPeaceBuddy, PermissionGroupBeyondBuddy>, string>} */
const permissionLabels = {
    administrateDeceasedPersonGroup: 'Verwalten',
    administrateGraveGroup: 'Verwalten',
    extendedReadPeaceBuddy: 'Erweitertes Lesen', // also defined in other applications like driveBuddy
    readGraveRecord: 'Grab Eintrag lesen',
    createGraveRecord: 'Grab Eintrag anlegen',
    updateGraveRecord: 'Grab Eintrag bearbeiten',
    deleteGraveRecord: 'Grab Eintrag löschen',
};

export {
    applicationConfiguration,
    permissionGroupDefinition as permissionGroupDefinitionPeaceBuddy,
    // entityLabels as entityLabelsPeaceBuddy,
    permissionLabels as permissionLabelsPeaceBuddy,
    managedEntityKeys as managedEntityKeysPeaceBuddy,
    defaultLinkSchemaOptions as defaultLinkSchemaOptionsPeaceBuddy,
};
