import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    Box, Tab, Tabs,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';
import {ItemData} from 'components/Form/ItemData';

import _ from 'lodash';
import {TabPanel} from 'assets/theme/layout/TabPanel';
import {useGlobalState} from 'hooks/useGlobalState';

import {FormHeadContainer} from 'components/Form/FormHeadContainer';
import {useNavigate, useLocation} from 'react-router-dom';

const initialGrants = {
    attributesReadable: false,
    attributesUpdatable: false,
    permissionsReadable: false,
    permissionsUpdatable: false,
};

/**
 * This page shows a form with tabs, surrounded by ItemData
 * @param {import('components/Form/form').TabFormsContainerProps} props TabFormsContainer properties
 * @returns {React.ReactElement} The TabFormsContainer component.
 */
function TabFormsContainer({
    itemDataProps, tabs, currentTab, setCurrentTab: parentSetCurrentTab, identifier, path, breadCrumbs: parentBreadCrumbs,
}) {
    const {getFormHasChangesState} = useGlobalState();
    const [grants, setGrants] = useState(initialGrants);
    const [subGrants, setSubGrants] = useState({});
    const [breadCrumbs, setBreadCrumbs] = useState([...parentBreadCrumbs, {label: tabs?.[0]?.label}]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const location = useLocation();
    const navigate = useNavigate();

    const {hash} = location; // This will give you the hash (e.g., #section1)

    const setCurrentTab = useCallback((tabIndex) => {
        // const targetTab = tabs[tabIndex];
        // const disabled = targetTab.enabledCondition && !(_.get(grants, targetTab.enabledCondition) || _.get(subGrants, targetTab.enabledCondition));
        const hashId = hash.substring(1, hash.length);
        if (hashId !== tabs[tabIndex].id) {
            navigate(`${path ? `/${path}` : ''}#${tabs[tabIndex].id}`, {replace: !hashId});
        }
        setBreadCrumbs([
            ...parentBreadCrumbs,
            {label: tabs?.[tabIndex]?.label},
        ]);
        parentSetCurrentTab(tabIndex);
        // if (!disabled) {
        //     if (hashId !== tabs[tabIndex].id) {
        //         navigate(`#${tabs[tabIndex].id}`, {replace: !hashId});
        //     }
        //     parentSetCurrentTab(tabIndex);
        // } else if (tabIndex) setCurrentTab(0); // only try to set if the tabindex is not already 0
    }, [parentBreadCrumbs, path, grants, currentTab, hash, subGrants, hash, tabs, navigate, parentSetCurrentTab]);

    const setTabById = useCallback((id) => {
        const tab = tabs.findIndex((t) => t.id === id);
        if (tab !== -1) {
            setCurrentTab(tab);
        } else {
            setCurrentTab(0);
        }
    }, [tabs, navigate, setCurrentTab]);

    const tabHasChanges = useMemo(() => _.reduce(tabs, (result, tab) => {
        if (!tab.formWrapperId) {
            return result;
        }
        // eslint-disable-next-line no-param-reassign
        result[tab.formWrapperId] = getFormHasChangesState(tab.formWrapperId);
        return result;
    }, {}), [tabs, getFormHasChangesState]);

    /**
     * Callback function, when the item data context has a data change
     */
    const processChange = useCallback((data) => {
        if (data?.grants) {
            setGrants(data?.grants);
        } else {
            setGrants(initialGrants);
        }
        if (data?.subGrants) {
            setSubGrants(data?.subGrants);
        } else {
            setSubGrants({});
        }
    }, [setGrants, setSubGrants]);

    useEffect(() => {
        if (hash) {
            const hashId = hash.substring(1, hash.length);
            setTabById(hashId);
        } else {
        // Resetting tab to be unselected
            setCurrentTab(0);
        }
    }, [identifier, hash, setCurrentTab, setTabById]);

    // useEffect(() => {
    //     setCurrentTab(0);
    // }, [identifier, setCurrentTab]);

    const content = (
        <>
            <FormHeadContainer breadCrumbs={breadCrumbs}>
                <Box>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={currentTab}
                        onChange={(event, tab) => setCurrentTab(tab)}
                        aria-label="form tabs"
                        sx={{
                            marginRight: 'auto',
                            '.MuiTabs-scrollButtons': {
                                '&.Mui-disabled': {opacity: 0.3},
                            },
                        }}
                    >
                        {_.map(tabs, (tab, value) => {
                            const disabled = tab.enabledCondition && !(_.get(grants, tab.enabledCondition) || _.get(subGrants, tab.enabledCondition));
                            const changedMark = tabHasChanges[tab.formWrapperId] ? ' *' : '';
                            const tabLabel = `${tab.label}${changedMark}`;
                            return (
                                <Tab
                                    key={value}
                                    value={value}
                                    aria-label={tab.id}
                                    iconPosition="end"
                                    disabled={disabled}
                                    {..._.omit(tab, 'enabledCondition', 'formWrapperId', 'quickGuideId', 'getChildren')}
                                    label={(!isMobile || _.size(tabs) <= 2) && tabLabel}
                                />
                            );
                        })}
                    </Tabs>
                </Box>
                <Box id={`${identifier}action-button-frame-main`}>
                    {_.map(tabs, (tab, key) => (
                        <Box key={key} sx={{display: 'flex', flexWrap: 'nowrap'}}>
                            <span id={`${identifier}action-button-frame-${tab.id}-actions`} style={{display: currentTab === key ? 'flex' : 'none', flexWrap: 'nowrap'}} />
                            <span id={`${identifier}action-button-frame-${tab.id}-navigation`} style={{display: currentTab === key ? 'flex' : 'none', flexWrap: 'nowrap'}} />
                            <span id={`${identifier}action-button-frame-${tab.id}-guides`} style={{display: currentTab === key ? 'flex' : 'none', flexWrap: 'nowrap'}}>
                                {tab.quickGuideId && <QuickGuideLink id={tab.quickGuideId} isActionButton />}
                            </span>
                        </Box>
                    ))}
                </Box>
            </FormHeadContainer>
            <Box sx={{marginTop: '1.5rem'}}>
                {_.map(tabs, (tab, key) => {
                // check show condition
                    if (tab.enabledCondition && !(_.get(grants, tab.enabledCondition) || _.get(subGrants, tab.enabledCondition))) {
                        return null;
                    }
                    return (
                        <TabPanel
                            id={`${identifier}${tab.id}_tab`}
                            key={key}
                            tabId={tab.id}
                            active={currentTab === key}
                        >
                            {tab.getChildren(grants, subGrants, setTabById)}
                        </TabPanel>
                    );
                })}
            </Box>
        </>
    );

    if (!itemDataProps) {
        return content;
    }

    return (
        <ItemData
            {...itemDataProps}
            onChange={(data, clearSingle) => {
                itemDataProps.onChange?.(data, clearSingle);
                processChange(data);
            }}
        >
            {content}
        </ItemData>
    );
}
export {TabFormsContainer};
