import React from 'react';
import {Listing} from 'components/Form/Listing';
import {differenceInDays, parseISO, isValid} from 'date-fns';
import {
    AddCircleOutline,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {
    Box, Chip, Grid, LinearProgress, Tooltip, Typography,
} from '@mui/material';
import {deleteGrave} from 'graphql/peaceBuddy/Grave/mutations';
import {listGraves} from 'graphql/peaceBuddy/Grave/queries';

import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import _ from 'lodash';
import {
    formatDateTime, formatDateTimeString,
    formatDaysToPeriod,
    isBeforeToday,
} from 'helper/date';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';
import {cemeteriesDataSchema, graveTypeDataSchema} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';

/**
 * Returns an appropriate icon for a grave based on its unit positions
 * The color of the position icons is based on their status (free or not)
 * @param {import('applications/peacebuddy/types').GraveEntity} grave - The grave entity object.
 * @returns {React.ReactNode} - The appropriate icons
 */
const getGraveUnitPositionStatusIcons = (grave) => {
    if (!grave) {
        return null;
    }
    const {unitPositions} = grave;

    if (!unitPositions) {
        return null;
    }

    // // // Check if the lease expiration date is within a 10-day range from today
    // const isWithinRange = isDateWithinRange(leaseExpirationDate, 10);

    return (
        <Box style={{display: 'flex'}} key={grave.id}>
            {_.map(_.flatMap(unitPositions), (unitPosition) => {
                if (unitPosition.graveRecordId) {
                    return (
                        <Box
                            key={unitPosition.id}
                            sx={{
                                border: 'solid 1px', borderColor: 'primary.main', backgroundColor: 'primary.main', width: '3px', height: '1rem', margin: '1px',
                            }}
                        />
                    );
                }
                return (
                    <Box
                        key={unitPosition.id}
                        sx={{
                            border: 'solid 1px', width: '3px', height: '1rem', margin: '1px',
                        }}
                    />
                );
            })}
        </Box>
    );

    // // If the grave is free, use 'success' color
    // if (grave.free) {
    //     return <CircleOutlined color="success" />;
    // }

    // // If the grave is either marked as fellHome or the lease expiration is within range, use 'warning' color
    // if (grave.fellHome || isWithinRange) {
    //     return <AccountCircleOutlined color="warning" />;
    // }

    // // Otherwise, the grave is in use and not free, use 'error' color
    // return <AccountCircleOutlined color="error" />;
};

/**
 * Generates a label for a grave entity by concatenating its division, subdivision, and number.
 * The label format is "division/subdivision/nr", omitting any null or undefined parts.
 * @param {import('applications/peacebuddy/types').GraveEntity} grave - The grave entity object containing division, subdivision, and nr.
 * @returns {string} The formatted label as "division/subdivision/nr", with missing parts omitted.
 */
// const getGraveLabel = (grave) => {
//     const details = _.join(_.compact([grave.division, grave.subDivision, grave.nr]), '/');
//     return `${grave.generalNr} ${details ? ` (${details})` : ''}`;
// };

/**
 * Generates a concatenated string of labels for an array of grave entities.
 * The labels for each grave are formatted using `getGraveLabel` and combined into a single string.
 * @param {Array<import('applications/peacebuddy/types').GraveEntity>} graves - Array of grave entities.
 * @returns {string} A string with labels for each grave, joined by a comma.
 */
// const getGraveLabels = (graves) => _.join(_.map(graves, getGraveLabel), ', ');

/**
 * Calculates the progress of a grave lease over time based on the earliest `leaseStartDate`
 * and the most far `leaseExpirationDate` from a group of connected graves.
 * @param {Array<import('applications/peacebuddy/types').GraveEntity>} graves - Array of connected grave entities.
 * @returns {{
 *   totalDays: number,
 *   daysLeft: number,
 *   progress: number
 *   farthestLeaseExpirationDate: string
 * }} An object containing the total number of days between the lease start and expiration,
 * the days left until the expiration, and the overall progress (from 100 to 0).
 */
const calculateGraveLeaseProgress = (graves) => {
    try {
    // Extract the valid lease start and expiration dates
        const leaseDates = graves.map((grave) => ({
            leaseStartDate: grave.leaseStartDate ? parseISO(grave.leaseStartDate) : null,
            leaseExpirationDate: grave.leaseExpirationDate ? parseISO(grave.leaseExpirationDate) : null,
        }));

        // Get the most historic lease start date (earliest)
        const earliestLeaseStartDate = _.minBy(leaseDates, (date) => (isValid(date.leaseStartDate) ? date.leaseStartDate : Infinity))?.leaseStartDate;

        // Get the most far lease expiration date (latest)
        const farthestLeaseExpirationDate = _.maxBy(leaseDates, (date) => (isValid(date.leaseExpirationDate) ? date.leaseExpirationDate : -Infinity))?.leaseExpirationDate;

        // Calculate the total number of days between the lease start and expiration
        const totalDays = earliestLeaseStartDate ? differenceInDays(farthestLeaseExpirationDate, earliestLeaseStartDate) : 0;

        // Calculate the number of days left until the most far lease expiration date
        const daysLeft = farthestLeaseExpirationDate ? differenceInDays(farthestLeaseExpirationDate, new Date()) : 0;

        // // Calculate the progress (100% at the start, 0% at expiration)
        // const progress = Math.round(totalDays ? Math.max(0, Math.min(100, (daysLeft / totalDays) * 100)) : 0);

        // Calculate the progress (0% at the start, 100% at expiration)
        const progress = Math.round(totalDays ? Math.max(0, Math.min(100, ((totalDays - daysLeft) / totalDays) * 100)) : 0);

        return {
            totalDays,
            daysLeft,
            progress,
            farthestLeaseExpirationDate: formatDateTime(farthestLeaseExpirationDate, 'dd.MM.yyyy'),
        };
    } catch {
        return {
            totalDays: 0,
            daysLeft: 0,
            progress: 0,
            farthestLeaseExpirationDate: null,
        };
    }
};

/**
 * Groups the graves by their connections and returns an object with `generalNr`
 * as keys, where each key maps to an array of grouped graves.
 * If graves are connected, they will be grouped under the smallest `generalNr` in the group.
 * @param {Array<import('applications/peacebuddy/types').GraveEntity>} graves - The array of grave items.
 * @returns {object} - An object where the keys are the `generalNr` of graves.
 */
const groupGravesByConnections = (graves) => {
    const graveMap = {};
    const processed = new Set();

    /**
     *
     * @param {import('applications/peacebuddy/types').GraveEntity} grave - the grave
     * @param {Array<import('applications/peacebuddy/types').GraveEntity>} group - the group of graves
     */
    const findConnectedGraves = (grave, group) => {
        group.push(grave);
        processed.add(grave.id);

        grave.connections.forEach((connection) => {
            const connectedGrave = _.find(graves, {id: connection.grave.id});
            const {attributes} = connection;

            if (attributes?.connected && connectedGrave && !processed.has(connectedGrave.id)) {
                findConnectedGraves(connectedGrave, group);
            }
        });
    };

    graves.forEach((grave) => {
        if (!processed.has(grave.id)) {
            const group = [];
            findConnectedGraves(grave, group);

            // Find the lexicographically smallest generalNr in the group
            const smallestGeneralNr = _.minBy(group, 'generalNr').generalNr;

            // Add group to the map using the smallest generalNr
            graveMap[smallestGeneralNr] = group;
        }
    });

    // Sort the object by keys (generalNr)
    const sortedGraveMap = _.fromPairs(_.sortBy(_.toPairs(graveMap), ([key]) => key));

    return sortedGraveMap;
};

/**
 * Formular for list graves
 * @returns {React.ReactElement} The GraveListFormular component
 */
function GraveListFormular() {
    const messageKey = 'Grave_List';
    return (
        <ListData
            loadConfig={{
                query: listGraves,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
                postProcess: (data) => _.map(data, (g) => ({
                    ...g,
                    unitPositions: g.unitPositions ? JSON.parse(g.unitPositions) : null,
                    connections: _.map(g.connections, (connection) => ({
                        ...connection,
                        attributes: connection.attributes ? JSON.parse(connection.attributes) : null,
                    })),
                })),
            }}
            deleteConfig={{
                mutation: deleteGrave,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="peacebuddy_settings_grave_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createGrave')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider
                id="Grave-unit-filter"
                messageKey={messageKey}
            >
                <FilterElementText label="Grabkartennummer" path="generalNr" />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} lg={4}><FilterElementAutocomplete label="Friedhof" path="cemeteryId" dataSchema={cemeteriesDataSchema} /></Grid>
                    <Grid item xs={5} sm={6} lg={4}><FilterElementAutocomplete label="Typ" path="type" dataSchema={graveTypeDataSchema} /></Grid>
                    <Grid item xs={3} sm={6} lg={2}><FilterElementCheckmark label="Sarg" path="coffinGrave" /></Grid>
                    <Grid item xs={3} sm={6} lg={2}><FilterElementCheckmark label="Urne" path="urnGrave" /></Grid>
                    <Grid item xs={6} sm={4}><FilterElementText label="Abteilung/Baumkreis" path="division" /></Grid>
                    <Grid item xs={6} sm={4}><FilterElementText label="Reihe/Zusatz" path="subDivision" /></Grid>
                    <Grid item xs={12} sm={4}><FilterElementText label="Nummer" path="nr" /></Grid>
                    <Grid item xs={3} sm={3} md={4}><FilterElementCheckmark label="Frei" path="free" /></Grid>
                    <Grid item xs={5} sm={5} md={4}><FilterElementCheckmark label="Heimgefallen" path="fellHome" /></Grid>
                    <Grid item xs={4} sm={4} md={4}><FilterElementCheckmark label="Wahlgrab" path="choosable" /></Grid>
                </Grid>
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/Listing').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Frei',
                                            // @ts-ignore
                                            renderItem: getGraveUnitPositionStatusIcons,
                                        },
                                    ],
                                    boxProps: {style: {width: '40px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Identifizierung',
                                            renderItem: (item) => (
                                                <Tooltip title="Identifizierung">
                                                    <Typography noWrap fontWeight="bold">{_.join(_.compact([item.division, item.subDivision, item.nr]), '/')}</Typography>
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            title: 'Grabkartennummer',
                                            renderItem: (item) => (
                                                <Tooltip title="Grabkartennummer"><Typography noWrap>{item.generalNr}</Typography></Tooltip>
                                            ),
                                        },
                                    ],
                                    boxProps: {
                                        style: {width: '100px'},
                                    },
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Vergeben von',
                                            renderItem: (item) => (
                                                <Tooltip title="Vergeben seit">
                                                    <Typography
                                                        noWrap
                                                        textOverflow="ellipsis"
                                                        color={(item.leaseExpirationDate && isBeforeToday(item.leaseExpirationDate)) ? 'gray' : 'unset'}
                                                    >
                                                        {`VS: ${formatDateTimeString(item.leaseStartDate, 'dd.MM.yyyy')}`}
                                                    </Typography>
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            title: 'Vergeben bis',
                                            renderItem: (item) => (
                                                <Tooltip title="Vergeben bis">
                                                    <Typography
                                                        noWrap
                                                        textOverflow="ellipsis"
                                                        color={(item.leaseExpirationDate && isBeforeToday(item.leaseExpirationDate)) ? 'gray' : 'unset'}
                                                    >
                                                        {`VB: ${formatDateTimeString(item.leaseExpirationDate, 'dd.MM.yyyy')}`}
                                                    </Typography>
                                                </Tooltip>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '150px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Grabpositionen',
                                            renderItem: (item) => {
                                                const unitPositions = _.flatMap(item.unitPositions);
                                                const sumUnitPositions = unitPositions.length;
                                                const amount = _.filter(unitPositions, (unitPosition) => !unitPosition.graveRecordId).length;
                                                return (
                                                    <Tooltip title="Verfügbarkeit der Grabpositionen">
                                                        <Typography noWrap textOverflow="ellipsis">{`GE: ${amount} / ${sumUnitPositions}`}</Typography>
                                                    </Tooltip>
                                                );
                                            },
                                        },
                                        {
                                            title: 'Letztes Beisetzung',
                                            renderItem: (item) => (
                                                <Tooltip title="Letzte Beisetzung">
                                                    <Typography noWrap textOverflow="ellipsis">{`LB: ${formatDateTimeString(item.lastFuneral, 'dd.MM.yyyy')}`}</Typography>
                                                </Tooltip>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '150px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Info',
                                            renderItem: (item) => {
                                                const {free, choosable, fellHome} = item;
                                                const {daysLeft} = calculateGraveLeaseProgress([item]);
                                                const soonFree = !free && daysLeft < 14;
                                                const soonExtendable = !free && daysLeft < 100;
                                                return (
                                                    <>
                                                        {choosable && <Chip title="Wahlgrab" variant="outlined" label="WG" />}
                                                        {soonExtendable && choosable && <Chip variant="outlined" label="Verlängerung anfragen" />}
                                                        {free && <Chip title="Frei" variant="outlined" label="F" />}
                                                        {!free && fellHome && <Chip title="heimgefallen" variant="outlined" label="HG" />}
                                                        {soonFree && <Chip title="Status" variant="outlined" label="bald verfügbar" />}
                                                        {/* {!choosable && <Chip title="Verlängerung" label="nicht verlängerbar" />} */}
                                                        {/* {choosable && <Tooltip title="Kann verlängert werden"><SyncOutlined color="info" /></Tooltip>}
                                                        {!choosable && <Tooltip title="Kann nicht verlängert werden"><SyncDisabledOutlined color="info" /></Tooltip>}
                                                        {free && <Tooltip title="Das Grab kann vergeben werden"><Circle color="success" /></Tooltip>} */}
                                                    </>
                                                );
                                            },
                                        },
                                    ],
                                    boxProps: {style: {flexGrow: 1}, sx: {display: {xs: 'none', lg: 'unset'}}},
                                },
                            ],
                            renderGroupRow: (items, index, row) => {
                                const {progress, daysLeft} = calculateGraveLeaseProgress(items);

                                /** @type {'error' | 'success' | 'warning' | 'info'} */
                                let progressColor = 'success';

                                const free = _.every(items, 'free');

                                if (!free) {
                                    if (daysLeft < 20) {
                                        progressColor = 'error';
                                    } else if (daysLeft < 100) {
                                        progressColor = 'warning';
                                    } else {
                                        progressColor = 'info';
                                    }
                                }

                                return (
                                    <Box key={index}>
                                        {
                                        //     <Box display="flex" justifyContent="space-between">
                                        //     <Typography fontWeight={500}>
                                        //         {/* {free && 'Frei'}
                                        //         {!free && !soonAvailable && `Vergeben bis ${farthestLeaseExpirationDate}`}
                                        //         {!free && soonAvailable && `Verfügbar ab ${farthestLeaseExpirationDate}`} */}
                                        //     </Typography>
                                        //     <Box>
                                        //         {choosable && <Tooltip title="Kann verlängert werden"><SyncOutlined color="info" /></Tooltip>}
                                        //         {!choosable && <Tooltip title="Kann nicht verlängert werden"><SyncDisabledOutlined color="info" /></Tooltip>}
                                        //         {free && <Tooltip title="Das Grab kann vergeben werden"><CircleOutlined color="success" /></Tooltip>}
                                        //         {partialFree && <Tooltip title="Einzelne Gräber sind frei"><CircleOutlined color="warning" /></Tooltip>}
                                        //         {!free && !partialFree && <Tooltip title="Belegt"><BlockOutlined color="error" /></Tooltip>}
                                        //     </Box>
                                        // </Box>
                                        }
                                        <LinearProgress variant="determinate" value={progress} title={`${formatDaysToPeriod(daysLeft)} verbleibend`} color={progressColor} />
                                        {_.map(items, (item, index2) => row(item, `g${index}r${index2}`))}
                                    </Box>
                                );
                            },
                            prepareData: groupGravesByConnections,
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'peacebuddy_settings_grave_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'peacebuddy_settings_grave_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {GraveListFormular};
