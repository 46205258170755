import React, {
    useCallback,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Alert,
    Box,
    Chip,
    Grid,
    Tooltip,
} from '@mui/material';

import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {
    AddCircleOutline, ArrowLeft, ArrowRight, LinkOutlined, Refresh,
} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {FormElementImageContainer} from 'components/Form/FormElements/FormElementImageContainer';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

// @ts-ignore
import graveImg from 'applications/peacebuddy/settings/pages/images/AdobeStock_822230580.jpeg';
import {cemeteriesDataSchema, graveTypeDataSchema} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';
import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {FormElementCheckbox} from 'components/Form/FormElements/FormElementCheckbox';
import {FormElementGravePositions} from 'applications/peacebuddy/settings/forms/grave/FormElementGravePositions';
import {isDateWithinRange} from 'helper/date';
import {useClipboard} from 'hooks/useClipboard';

const {schema: validatorSchema} = require('beyond-validators/peaceBuddy/Grave');

/**
 * @type {import("helper/recommendation-manager").RecommendationConfig}
 */
const recommendationConfig = {
    variables: {global: {tenantId: 'tenantId'}},
    mask: {
        tenantId: true,
        cemeteryId: false,
        type: false,
    },
    valueDependencies: {
        restPeriod: ['cemeteryId', 'type'],
    },
    optionDependencies: {
        restPeriod: ['cemeteryId', 'type'],
    },
    querySpec: {
        queryName: 'getRestPeriodRecommendation',
        queryLabel: 'GetRestPeriodRecommendation',
        possibleAttributes: {
            restPeriod: true,
        },
        possibleInputVariables: {
            tenantId: 'ID!',
            cemeteryId: 'ID',
            type: 'String',
        },
    },
};

/**
 * The grace formular for creating and updating a grave
 * @param {import('applications/configuration').FormularProps} props - props passed to the component
 * @returns {React.ReactElement} The GraveFormular component.
 */
function GraveFormular({id, onSave, ...rest}) {
    // const [graveKindDataSchema, setGraveKindDataSchema] = useState(coffinGraveKindDataSchema);
    const isNewItem = id === 'create';
    const {copyToClipboard} = useClipboard();

    const canCreateGraves = useCanAccess('createGrave');

    /**
     * @param {'left'|'right'} position position to be rendered
     * @returns {React.ReactElement} arrow button
     */
    const getNavigationButton = useCallback((position) => (
        <FormElementContainer
            // conditionalRender={(data) => data?.routeParams?.id} // not possible -> wrong order possible
            attribute="connections"
            propsMapping={(data) => {
                const graveId = _.find(data?.value, (link) => {
                    const attributes = JSON.parse(link.attributes);
                    return (attributes?.position === position);
                })?.grave?.id;

                return {
                    disabled: !graveId,
                    routeParams: {id: graveId},
                };
            }}
        >
            <FormElementActionButton
                routeId="peacebuddy_settings_grave_route"
                context={FormContext}
                portalAnchorSelector={`#GraveForm${id}action-button-frame-base-navigation`}
                formWrapperIdPattern={`Grave#${id}`}
                deleteformWrapperIdPatternOnAccept
            >
                {position === 'left' ? <ArrowLeft /> : <ArrowRight />}
            </FormElementActionButton>
        </FormElementContainer>
    ), [id]);

    const onChangeCallback = useCallback((attribute, value, changeHandler) => {
        // another parameter "formData" can be obtained
        // if (attribute === 'type') {
        //     const typeSchemaOption = _.find(graveTypeDataSchema.options, (option) => option.value === value);
        //     setGraveKindDataSchema(typeSchemaOption?.formDataSchema ?? coffinGraveKindDataSchema);
        //     // reset the grave kind, because it is dependent on the type
        //     changeHandler({attribute: 'kind', value: null, displayValue: null});
        // }

        if (attribute === 'free') {
            changeHandler({attribute: 'leaseStartDate', value: null, displayValue: null});
            changeHandler({attribute: 'leaseExpirationDate', value: null, displayValue: null});
        }

        // if (attribute === 'restPeriod') {
        //     const start = formData.leaseStartDate?.displayValue;
        //     const expirationSet = !!(formData.leaseExpirationDate?.displayValue || formData.leaseExpirationDate?.value);
        //     console.log({start, expirationSet}, formData);

        //     const futureEndOfYearIsoString = getFutureEndOfYearIsoString(value, start?.toISOString());
        //     if (start && !expirationSet) {
        //         changeHandler({attribute: 'leaseExpirationDate', value: futureEndOfYearIsoString, displayValue: null});
        //     }
        // }
        // if (attribute === 'leaseStartDate') {
        //     const restPeriod = formData.restPeriod?.displayValue;
        //     const expirationSet = !!(formData.leaseExpirationDate?.displayValue || formData.leaseExpirationDate?.value);
        //     if (restPeriod && !expirationSet) {
        //         const futureEndOfYearIsoString = getFutureEndOfYearIsoString(restPeriod, value);
        //         const leaseExpirationDateInteracted = formData.leaseExpirationDate?.interacted;
        //         if (futureEndOfYearIsoString && !leaseExpirationDateInteracted) {
        //             changeHandler({attribute: 'leaseExpirationDate', value: futureEndOfYearIsoString, displayValue: null});
        //         }
        //     }
        // }
        // if (attribute === 'choosable') {
        //     const {units} = formData;
        //     if (!value && units?.displayValue !== 1) {
        //         changeHandler({attribute: 'units', value: 1, displayValue: 1});
        //     }
        // }
    }, []);
    return (
        <FormWrapper
            {...rest}
            isNewItem={isNewItem}
            validatorSchema={{
                schema: validatorSchema,
                type: (!isNewItem) ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(!isNewItem) ? 'Grave_Update' : 'Grave_Create'}
            onChangeCallback={onChangeCallback}
            recommendationConfig={
                isNewItem
                    ? recommendationConfig
                    : undefined
            }
            context={`Grave#${id}Base`}
        >
            <FormReset shouldClear={isNewItem} keepInitial />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="peacebuddy_settings_grave_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#GraveForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Grave#${id}`}
                    disabled={isNewItem || !canCreateGraves}
                    context={FormContext}
                    deleteformWrapperIdPatternOnAccept
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#GraveForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Grave#${id}`}
                    disabled={isNewItem}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <FormElementActionButton
                    portalAnchorSelector={`#GraveForm${id}action-button-frame-base-actions`}
                    disabled={isNewItem}
                    disableChangeCheck
                    callback={() => copyToClipboard(window.location.href, true)}
                >
                    <LinkOutlined />
                </FormElementActionButton>
                {getNavigationButton('left')}
                {getNavigationButton('right')}
                <FormElementImageContainer defaultImg={graveImg} alt="Grave" edit />
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementContainer
                            attribute="connections"
                            propsMapping={({value}) => {
                                if (_.some(value, ({attributes}) => JSON.parse(attributes)?.connected === true)) {
                                    return {
                                        children: (
                                            <Tooltip title="Dieses Grab ist mit anderen Gräbern verknüpft.">
                                                <Chip label="Familiengrab" />
                                            </Tooltip>
                                        ),
                                    };
                                }
                                return {};
                            }}
                        >
                            <FormElementInfoChips showReadonly />
                        </FormElementContainer>
                        <Grid item xs={8}>
                            <FormElementContainer
                                attribute="connections"
                                propsMapping={({value}, changeHandler, get) => (
                                    {
                                        disabled: _.get(get('grants'), 'value.deletable') === false
                                            || _.some(value, ({attributes}) => JSON.parse(attributes)?.connected === true),
                                    })}
                            >
                                <FormElementAutocomplete
                                    label="Friedhof"
                                    attribute="cemeteryId"
                                    optionReference="cemetery"
                                    dataSchema={cemeteriesDataSchema}
                                    usePopular
                                    readOnly={!isNewItem}
                                />
                            </FormElementContainer>
                        </Grid>
                        <Grid item xs={4}>
                            <FormElementTextField label="Grabkartennummer" attribute="generalNr" />
                        </Grid>
                        <Grid item xs={8}>
                            <FormElementAutocomplete attribute="type" label="Art" dataSchema={graveTypeDataSchema} />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <FormElementContainer
                                attribute="type"
                                propsMapping={(elementData) => ({disabled: !elementData?.value})}
                            >
                                <FormElementAutocomplete attribute="kind" label="Form" dataSchema={graveKindDataSchema} />
                            </FormElementContainer>
                        </Grid> */}

                        <Grid item xs={4}>
                            <FormElementContainer
                                attribute="type"
                                propsMapping={(elementData) => ({
                                    label: _.find(graveTypeDataSchema.options, (option) => option.value === elementData?.value)?.divisionLabel ?? 'Abteilung',
                                })}
                            >
                                <FormElementTextField label="Abteilung" attribute="division" />
                            </FormElementContainer>
                        </Grid>
                        <Grid item xs={8}>
                            <FormElementContainer
                                attribute="type"
                                propsMapping={(elementData) => ({
                                    label: _.find(graveTypeDataSchema.options, (option) => option.value === elementData?.value)?.subDivisionLabel ?? 'Reihe',
                                })}
                            >
                                <FormElementTextField label="Reihe" attribute="subDivision" />
                            </FormElementContainer>
                        </Grid>
                        <Grid item xs={4}>
                            <FormElementTextField label="Nummer" attribute="nr" />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Grid container spacing={2} marginTop="1rem">
                <Grid item xs={8} md={4}>
                    <FormElementDatePicker label="Letzte Beisetzung" attribute="lastFuneral" />
                </Grid>
                <Grid item xs={4} md={2}>
                    <FormElementTextField label="Ruhefrist (Jahre)" attribute="restPeriod" type="int" />
                </Grid>
                {/* <Grid item md={6} sx={{display: {xs: 'none', md: 'block', lg: 'none'}}} /> */}
                <Grid item xs={6} md={3}>
                    <FormElementDatePicker label="Vergeben ab" attribute="leaseStartDate" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormElementDatePicker label="Vergeben bis" attribute="leaseExpirationDate" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormElementContainer
                        attribute="unitPositions"
                        propsMapping={(elementData) => ({
                            disabled: _.some(_.flatMap(elementData?.value), (position) => !!position?.graveRecordId),
                        })}
                    >
                        <FormElementCheckbox label="Frei" attribute="free" />
                    </FormElementContainer>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormElementContainer
                        attribute="leaseExpirationDate"
                        propsMapping={(elementData) => ({
                            disabled: elementData.value && !isDateWithinRange(elementData.value, 10),
                        })}
                    >
                        <FormElementCheckbox label="Heimgefallen" attribute="fellHome" />
                    </FormElementContainer>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormElementContainer
                        attribute="connections"
                        propsMapping={(elementData, cH, get) => {
                            const isConnected = _.some(elementData?.value, ({attributes}) => JSON.parse(attributes)?.connected === true);
                            const isChoosable = get('choosable')?.value;
                            return {
                                disabled: isConnected && isChoosable,
                                style: (isConnected && !isChoosable) ? {border: 'solid 1px red'} : {},
                            };
                        }}
                    >
                        <FormElementCheckbox label="Wahlgrab" attribute="choosable" />
                    </FormElementContainer>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormElementCheckbox label="Ehrengrab" attribute="honoraryGrave" />
                </Grid>
                <FormElementContainer
                    attribute="connections"
                    conditionalRender={(value, get) => {
                        const isConnected = _.some(value?.value, ({attributes}) => JSON.parse(attributes)?.connected === true);
                        if (isConnected) {
                            return !(get('choosable')?.value);
                        }
                        return false;
                    }}
                >
                    <Grid item xs={12}>
                        <Alert severity="warning">Dieses Reihengrab ist mit weiteren Gräbern verknüpft und bildet ein Familiengrab. Nur Wahlgräber sollten Mehrfachgräber sein.</Alert>
                    </Grid>
                </FormElementContainer>
                <Grid item xs={12}>
                    <FormElementTextField label="Vermerk" attribute="notes" />
                </Grid>
                <Grid item xs={12}>
                    <FormElementGravePositions />
                </Grid>
                <Grid item xs={12}>
                    <FormElementFilesUpload />
                </Grid>

                {isNewItem && (
                    <Grid item xs={12}>
                        <Alert severity="warning">
                        Nach dem Erstellen wird automatisch der gesamte Mandant berechtigt das Grab zu lesen und zu bearbeiten.
                        Sollte die Nutzung eingeschränkt werden, so kann dies nach dem Anlegen im Tab
                            {' '}
                            <i>Berechtigungen</i>
                            {' '}
                        verwaltet werden.
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <FormElementLoadingButton label="Speichern" />
                </Grid>
            </Grid>
        </FormWrapper>
    );
}

export {GraveFormular};
