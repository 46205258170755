import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {DeceasedPersonFormular} from 'applications/peacebuddy/settings/forms/deceasedPerson/DeceasedPersonFormular';
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {GraveRecordsListFormular} from 'applications/peacebuddy/settings/forms/grave/GraveRecordsListFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {
    AddCircleOutline, ArrowLeftOutlined, ArticleOutlined, BlurOnOutlined, ListOutlined, LockOutlined,
} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';

import {createDeceasedPerson, updateDeceasedPerson} from 'graphql/peaceBuddy/DeceasedPerson/mutations';
import {getDeceasedPerson} from 'graphql/peaceBuddy/DeceasedPerson/queries';
import {isValidGUID} from 'helper/guid';
import {NotFound} from 'applications/pages/NotFound';
import {useFindRoute} from 'hooks/useFindRoute';
import {useGlobalState} from 'hooks/useGlobalState';
import {Box, Typography} from '@mui/material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {ProtocolEntryListFormular} from 'applications/beyondbuddy/settings/forms/protocolEntry/ProtocolEntryListFormular';
import {MenuLink} from 'components/MenuLink';
import {ProtocolEntryFormular} from 'applications/beyondbuddy/settings/forms/protocolEntry/ProtocolEntryFormular';

const mask = {
    id: true,
    tenantId: true,
    referenceId: false,
    gender: true,
    titlePrefixed: false,
    firstName: true,
    lastName: true,
    titlePostfixed: false,
    nationalityCountryCode: true,
    dateOfBirth: true,
    placeOfBirth: false,
    placeOfBirthCountryCode: false,
    dateOfDeath: true,
    placeOfDeath: false,
    placeOfDeathCountryCode: false,
    stillbirth: false,
    cremation: false,
    ashCapsuleNr: false,
    homeStorage: false,
    ashCapsuleLocation: false,
    burried: false,
    notes: false,
    attachmentKeys: false,
};

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the deceasedPerson formular
 */
const getDeceasedPersonFormular = (props) => <DeceasedPersonFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`DeceasedPerson#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#DeceasedPersonForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            availablePermissions: createPermissionsObject(organizationEntityKeys, ['read', 'updateGroup', 'administrateDeceasedPersonGroup']),
            defaultPermissions: createPermissionsObject(organizationEntityKeys, ['administrateDeceasedPersonGroup']),
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

/**
 * This page shows a create and update form for a deceasedPerson.
 * @returns {React.ReactElement} The DeceasedPersonPage component.
 */
function DeceasedPersonPage() {
    const {id, subId} = useParams();
    const isNewItem = id === 'create';
    const {getGlobal} = useGlobalState();
    const userId = getGlobal('userId');
    const user = getGlobal('user');
    const navigate = useNavigate();
    const findRoute = useFindRoute();
    const [currentTab, setCurrentTab] = useState(0);
    const proRef = useRef();
    const canCreateProtocolEntries = useCanAccess('createProtocolEntry');

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();
    const location = useLocation();

    const {hash} = location; // This will give you the hash (e.g., #section1)

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        ...(id !== 'create') ? {
            mutation: updateDeceasedPerson,
            mask,
        } : {
            mutation: createDeceasedPerson,
            mask: _.omit(mask, 'id'),
        },
        postProcess: (data) => ({
            ...data,
            attachmentKeys:
                'attachments' in data && data.attachments instanceof Array
                    ? JSON.stringify(data.attachments.map((item) => item.key))
                    : '[]',
        }),
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getDeceasedPerson,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: (data) => ({
            ...data,
            attachmentKeys:
                'attachments' in data && data.attachments instanceof Array
                    ? JSON.stringify(data.attachments.map((item) => item.key))
                    : '[]',
        }),
    }), [id]);

    const getGraveRecordEntries = useCallback(() => (
        <GraveRecordsListFormular
            deceasedPersonId={id}
            actions={{
                edit: {
                    callBack: (item) => navigate(`/${generatePath(
                        findRoute('peacebuddy_settings_grave_sub_route').path,
                        {id: item.graveId, subId: item.id},
                    )}#graveRecord`),
                },
            }}
            actionButtonProps={{portalAnchorSelector: `#DeceasedPersonForm${id}action-button-frame-graveRecord-actions`}}
        />
    ), [id, navigate, generatePath, findRoute]);

    const initialDataProtocolEntry = useMemo(() => (subId === 'create' ? ({
        protocolDate: new Date().toISOString(),
        clerkUserId: userId,
        clerkUser: user,
    }) : {}), [subId, userId]);

    const getProtocolEntries = useCallback(() => (
        <>
            {(!subId && hash === '#protocolEntry') && (
                <>
                    <Box display="flex" justifyContent="space-between">
                        {/* Span to avoid the actionbutton frame shifting to the right to take the buttons place */}
                        <span>
                            {canCreateProtocolEntries && (
                                <FormElementActionButton
                                    dataTest="FormElementActionButton_Add"
                                    callback={
                                        () => navigate(`/${generatePath(findRoute('peacebuddy_settings_deceasedPerson_sub_route').path, {id, subId: 'create'})}#protocolEntry`)
                                    }
                                    portalAnchorSelector={`#DeceasedPersonForm${id}action-button-frame-protocolEntry-actions`}
                                >
                                    <AddCircleOutline />
                                </FormElementActionButton>
                            )}
                        </span>
                    </Box>

                    <ProtocolEntryListFormular
                        entityTypeId={`DeceasedPerson#${id}`}
                        actions={{
                            edit: {
                                callBack:
                                (item) => navigate(`/${generatePath(findRoute('peacebuddy_settings_deceasedPerson_sub_route').path, {id, subId: item.id})}#protocolEntry`),
                            },
                        }}
                        actionButtonProps={{portalAnchorSelector: `#GraveForm${id}action-button-frame-protocolEntry-actions`}}
                        listDataRef={proRef}
                    />
                </>
            )}
            {(subId && hash === '#protocolEntry') && (
                <>
                    <MenuLink
                        to={`/${generatePath(findRoute('peacebuddy_settings_deceasedPerson_route').path, {id})}#protocolEntry`}
                        onClick={() => {
                        // @ts-ignore
                            proRef.current?.reload?.();
                        }}
                    >
                        <Box display="flex" flexWrap="nowrap" alignContent="center">
                            <ArrowLeftOutlined color="primary" style={{marginRight: '2rem'}} />
                            <Typography color="primary" textTransform="uppercase">Zurück zur Übersicht</Typography>
                        </Box>
                    </MenuLink>

                    <ProtocolEntryFormular
                        id={subId}
                        entityTypeId={`DeceasedPerson#${id}`}
                        actionButtonProps={{portalAnchorSelector: `#DeceasedPersonForm${id}action-button-frame-protocolEntry-actions`}}
                        onSave={(result) => {
                            if (subId === 'create' && 'id' in result && result?.id) {
                                navigate(`/${generatePath(findRoute('peacebuddy_settings_deceasedPerson_sub_route').path, {id, subId: result?.id})}#protocolEntry`);
                            }
                        }}
                        initialData={initialDataProtocolEntry}
                    />
                </>
            )}
        </>
    ), [id, subId, hash, canCreateProtocolEntries, initialDataProtocolEntry, proRef.current, navigate, generatePath, findRoute]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `DeceasedPersonForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
                initialData: {nationalityCountryCode: 'AT', placeOfBirthCountryCode: 'AT', placeOfDeathCountryCode: 'AT'},
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getDeceasedPersonFormular({id, onSave}),
                formWrapperId: `DeceasedPerson#${id}Base`,
                quickGuideId: 'peacebuddy_general_deceasedPerson',
            }, {
                id: 'graveRecord',
                label: 'Beisetzungen',
                icon: <ArticleOutlined />,
                enabledCondition: 'graveRecordReadable',
                getChildren: () => id !== 'create' && getGraveRecordEntries(),
                quickGuideId: 'peacebuddy_general_graveRecord',
            }, {
                id: 'protocolEntry',
                label: 'Bewegungsdaten',
                icon: <ListOutlined />,
                enabledCondition: 'protocolEntryReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: () => !isNewItem && getProtocolEntries(),
                formWrapperId: `DeceasedPerson#${id}peaceBuddyDeceasedPersonProtocolEntries`,
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants) => id !== 'create' && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `DeceasedPerson#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab,
            getDeceasedPersonFormular, getPermissionFormular, setCurrentTab, onSave]);

    // check if id is a guid and return notfound it is not
    if (!isValidGUID(id)) {
        return <NotFound />;
    }

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} breadCrumbs={[{label: 'Verstorbene Person'}]} />
        </LayoutContainer>
    );
}
export {DeceasedPersonPage};
