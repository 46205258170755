import React, {useCallback, useContext, useMemo} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Box,
    Button,
    Grid,
    TableCell,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {add} from 'date-fns';

import {FormReset} from 'components/Form/FormReset';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {ItemData, ItemDataContext} from 'components/Form/ItemData';
import {createGraveRecord, updateGraveRecord} from 'graphql/peaceBuddy/GraveRecord/mutations';
import {getGraveRecord} from 'graphql/peaceBuddy/GraveRecord/queries';
import {Delete, OpenInBrowserOutlined, Refresh} from '@mui/icons-material';
import {generatePath, useNavigate} from 'react-router-dom';
import {useFindRoute} from 'hooks/useFindRoute';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementGraveRecordPositionChooser} from 'applications/peacebuddy/settings/forms/grave/FormElementGraveRecordPositionChooser';
import {deceasedPersonDataSchema} from 'applications/peacebuddy/settings/forms/grave/DeceasedPersonSchema';
import {userDataSchema} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useGlobalState} from 'hooks/useGlobalState';
import {FormElementEntityLinkAttributes} from 'components/Form/FormElements/FormElementEntityLinkAttributes';
import {UnlockButton} from 'assets/theme/components/UnlockButton/UnlockButton';
import {useMessage} from 'hooks/useMessage';
import {Exceptions as PeaceBuddyExceptions} from 'applications/peacebuddy/messages/Exceptions';
import {Warnings as PeaceBuddyWarnings} from 'applications/peacebuddy/messages/Warnings';

const {schema: validatorSchema} = require('beyond-validators/peaceBuddy/GraveRecord');

/** @type {Record<keyof(Omit<import('applications/peacebuddy/types').GraveRecordEntity, 'active'>), boolean>} */
const mask = {
    id: true,
    tenantId: true,
    clerkUserId: false,
    graveId: true,
    deceasedPersonId: true,
    graveUnitId: true,
    dateOfFuneral: true,
    burryDeadline: true,
    notes: false,
    customers: false,
    attachmentKeys: false,
};

const errorMapping = [
    {technical: 'The deceased person already has an active grave record', de: 'Die verstorbene Person hat einen aktiven Grabeintrag!'},
    {technical: 'The grave has no remaining positions available', de: 'Das Grab hat keine freie Position übrig'},
    {technical: 'The grave unit is already taken', de: 'Die Grabeinheit ist im angegebenen Zeitraum bereits vergeben!'},
];

/**
 * Sets the `graveRecordId` on the `GraveUnitPosition` object with the given `id`.
 * @param {Array<Array<import('applications/peacebuddy/types').GraveUnitPosition>>} unitPositions - A nested array of `GraveUnitPosition` objects.
 * @param {string} graveRecordId - The `graveRecordId` to set on the matching `GraveUnitPosition`.
 * @param {string} [graveUnitId] - The ID of the `GraveUnitPosition` to update. If not provided, only a remove will be performed
 * @returns {Array<Array<import('applications/peacebuddy/types').GraveUnitPosition>>} The updated `unitPositions` array.
 */
function setGraveRecordId(unitPositions, graveRecordId, graveUnitId) {
    // Flatten the nested arrays into a single array
    const flattenedPositions = _.flatten(unitPositions);

    // Find the GraveUnitPosition with the matching graveRecordId
    let position = _.find(flattenedPositions, {graveRecordId});
    if (position) {
        _.set(position, 'graveRecordId', null);
    }

    if (graveUnitId) {
    // Find the GraveUnitPosition with the matching id
        position = _.find(flattenedPositions, {id: graveUnitId});

        // If the position is found, set the graveRecordId
        if (position) {
            _.set(position, 'graveRecordId', graveRecordId);
        }
    }

    return unitPositions;
}

const tableHeadLabels = [{
    label: 'Anteile',
}];

/** @type {import('applications/configuration').ManagedObject[]} */
const availableEntities = ['Customer'];

/**
 *
 * @param {any} item - the link
 * @param {import('components/Form/FormElements/FormElementEntityLink').Links} currentLinks - the current links of the entity
 * @param {Record<string, any>} attributes - the attribute(s) to set
 * @param {import('components/Form/form').ChangeHandler} changeHandler - function to update the state
 */
const setCustomerLinks = (item, currentLinks, attributes, changeHandler) => {
    const entityValueIndex = _.findIndex(currentLinks.links, (link) => link.leftEntityTypeId === `${item.type}#${item.id}`);
    // new entity in the list
    if (entityValueIndex === -1) {
        currentLinks.links.push({leftEntityTypeId: `${item.type}#${item.id}`, attributes});
    } else {
        const entityValue = currentLinks.links[entityValueIndex];
        _.merge(entityValue.attributes, attributes);
    }

    // populate changes
    changeHandler({
        attribute: 'customers',
        value: {
            ...currentLinks,
            links: Array.from(currentLinks.links),
        },
        interacted: true,
    });
};

/**
 *
 * @param {any} item - the link
 * @param {import('components/Form/FormElements/FormElementEntityLink').Links} currentLinks - the current links of the entity
 * @param {import('components/Form/form').ChangeHandler} changeHandler - function to update the state
 */
const removeCustomerLink = (item, currentLinks, changeHandler) => {
    const entityValueIndex = _.findIndex(currentLinks.links, (link) => link.leftEntityTypeId === `${item.type}#${item.id}`);
    if (entityValueIndex !== -1) {
        // populate changes
        changeHandler({
            attribute: 'customers',
            value: {
                ...currentLinks,
                [item.type]: _.filter(currentLinks[item.type], (link) => link.id !== item.id),
                links: _.filter(currentLinks.links, (link, index) => index !== entityValueIndex),
            },
            interacted: true,
        });
    }
};

/**
 * The graverecord formular for creating and updating a graverecord
 * @param {import('applications/configuration').FormularProps & {
 *  showGraveLink?: boolean,
 *  actionButtonProps: Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>
 *  onChangeCallback?: (attribute: string, value: any, changeHandler: import('components/Form/form').TFormContext['changeHandler'], formData: object) => void
 * }} props - props passed to the component
 * @returns {React.ReactElement} The GraveRecordFormular component.
 */
function GraveRecordFormular({
    id, onSave, showGraveLink, actionButtonProps, initialData, ...rest
}) {
    const isNewItem = id === 'create';
    /** @type {{data: import('applications/peacebuddy/types').GraveEntity, updateValue: import('components/Form/form').TItemDataContext['updateValue']}} */
    const {data: grave, updateValue} = useContext(ItemDataContext);
    const {getFormHasChangesState} = useGlobalState();
    const navigate = useNavigate();
    const findRoute = useFindRoute();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const {enqueueMessage} = useMessage();

    // eslint-disable-next-line function-paren-newline
    const validateCustomers = useCallback(
        /**
         *
         * @param {import('applications/peacebuddy/types').GraveRecordEntity} data - the grave record response from the api
         * @returns {import('applications/peacebuddy/types').GraveRecordEntity | false} - returns the same grave record when valid
         */
        (data) => {
            const {customers} = data;

            if (!_.size(customers)) {
                return data;
            }

            const sum = _.sumBy(customers, (customerData) => customerData.portion);
            if (sum < 100 && sum !== 100) {
                enqueueMessage(`grave_${id}_graveRecord_tab`, PeaceBuddyWarnings.GRAVERECORD_CUSTOMERS_PORTIONS_SUM_TOO_LOW);
            }
            if (sum > 100) {
                enqueueMessage(`grave_${id}_graveRecord_tab`, PeaceBuddyExceptions.GRAVERECORD_CUSTOMERS_PORTIONS_SUM_TOO_HIGH);
                return false;
            }

            return data;
        }, []);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        preProcess: (data) => validateCustomers({
            ...data,
            customers: _.map(data.customers?.links, (link) => ({
                customerId: _.get(link, 'leftEntityTypeId').split('#')[1],
                portion: _.get(link, 'attributes.portion', 0),
            })),
        }),
        postProcess: (data) => {
            const graveUnitPositions = grave?.unitPositions;
            setGraveRecordId(graveUnitPositions, data?.id, data?.graveUnitId);
            updateValue('unitPositions', graveUnitPositions);
            if (data?.burryDeadline > new Date().toISOString()) {
                updateValue('free', false);
            }
            return {
                ...data,
                customers: {
                    links: _.map(data.customers, (customer) => ({
                        leftEntityTypeId: `Customer#${customer.customerId}`,
                        permissions: [],
                        attributes: {
                            portion: customer.portion,
                        },
                    })),
                    customers: _.map(data.customers, (customer) => _.omit(customer.customer, 'portion')),
                },
            };
        },
        variables: {
            global: {tenantId: 'tenantId'},
            direct: {graveId: grave.id},
        },
        ...!isNewItem ? {
            mutation: updateGraveRecord,
            mask: _.omit(mask, 'graveId', 'deceasedPersonId'), // grave and deceased person can not be changed
        } : {
            mutation: createGraveRecord,
            mask: _.omit(mask, 'id'),
        },
    }), [id, grave, createGraveRecord, updateGraveRecord, updateValue, validateCustomers]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getGraveRecord,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: (data) => ({
            ...data,
            customers: {
                links: _.map(data.customers, (customer) => ({
                    leftEntityTypeId: `Customer#${customer.customerId}`,
                    permissions: [],
                    attributes: {
                        portion: customer.portion,
                    },
                })),
                customers: _.map(data.customers, (customer) => _.omit(customer.customer, 'portion')),
            },
        }),
    }), [id, getGraveRecord]);

    const goToGrave = useCallback(() => {
        navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_route').path, {id: grave?.id})}`);
    }, [grave?.id, findRoute]);

    const graveFormChanged = useMemo(() => {
        const changes = getFormHasChangesState(`Grave#${grave?.id}Base`);
        if (changes === true || changes === false) {
            return changes;
        }
        return _.intersection(changes, ['units', 'unitPositions']).length > 0;
    }, [getFormHasChangesState]);

    // eslint-disable-next-line function-paren-newline
    const getLineControls = useCallback(
        /**
         * @param {Record<string, any>} attributes - the entity link information
         * @param {boolean} locked - indicates, that no changes can be made
         * @param {(attributes: Record<string, any>)=>void} changeHandler - the form change handler
         * @returns {import('react').ReactNode} - returns the link controls
         */
        (attributes, locked, changeHandler) => {
            const controls = (
                <TextField
                    label="Anteil in %"
                    type="number"
                    value={attributes?.portion || ''}
                    disabled={locked}
                    onChange={(e) => {
                        const portion = Number(e.target.value);
                        if (_.isNaN(portion) || portion < 0 || portion > 100) { return; }
                        changeHandler({portion});
                    }}
                    data-test="FormElementEntityChooser_customers_portion"
                />
            );

            if (!isDesktop) {
                return controls;
            }
            return (
                <TableCell align="center">
                    {controls}
                </TableCell>
            );
        }, [isDesktop]);

    const getTableHead = useCallback(() => {
        if (!isDesktop) {
            return null;
        }
        return (
            <>
                {_.map(tableHeadLabels, (headItem, index) => <TableCell key={index} align={headItem.align ?? 'left'}><Typography fontWeight="bold">{headItem.label}</Typography></TableCell>)}
                <TableCell />
            </>
        );
    }, [isDesktop, tableHeadLabels]);

    const getAttributeLine = useCallback(
        /**
         *
         * @param {any} item - the entity link information
         * @param {import('components/Form/FormElements/FormElementEntityLink').Links} currentLinks - the current link information
         * @param {boolean} locked - indicates, that no changes can be made
         * @param {import('components/Form/form').ChangeHandler} changeHandler - the form change handler
         * @returns {import('react').ReactNode} - returns the attribute line
         */
        (item, currentLinks, locked, changeHandler) => {
            const currentLink = _.find(currentLinks.links, (link) => link.leftEntityTypeId === `${item.type}#${item.id}`);
            const lineControls = getLineControls(
                currentLink?.attributes,
                locked,
                (attributes) => setCustomerLinks(item, currentLinks, attributes, changeHandler),
            );
            const unlockRemove = (
                <UnlockButton
                    onClick={() => removeCustomerLink(item, currentLinks, changeHandler)}
                    data-test="FormElementEntityChooser_customers_delete_unlockbutton"
                    disabled={locked}
                >
                    <Delete />
                </UnlockButton>
            );
            if (!isDesktop) {
                return (
                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                        {lineControls}
                        {unlockRemove}
                    </Box>
                );
            }
            return (
                <>
                    {lineControls}
                    <TableCell align="center">
                        {unlockRemove}
                    </TableCell>
                </>
            );
        },
        [isDesktop, getLineControls, setCustomerLinks, removeCustomerLink],
    );

    return (
        <ItemData
            {...(!isNewItem) ? {loadConfig} : {}}
            saveConfig={saveConfig}
            initialData={{
                ...initialData,
            }}
            errorMapping={errorMapping}
        >
            <FormWrapper
                {...rest}
                isNewItem={isNewItem}
                validatorSchema={{
                    schema: validatorSchema,
                    type: (!isNewItem) ? 'update' : 'create',
                }}
                onSaveCallback={onSave}
                messageKey={(!isNewItem) ? 'GraveRecord_Update' : 'GraveRecord_Create'}
                context={`GraveRecord#${id}Base`}
                onChangeCallback={(attribute, value, changeHandler) => {
                    if (attribute === 'dateOfFuneral') {
                        const dateOfFuneral = Date.parse(value);
                        if (!_.isNaN(dateOfFuneral)) {
                            changeHandler({
                                attribute: 'burryDeadline',
                                value: add(dateOfFuneral, {years: grave?.restPeriod ?? 10}).toISOString(),
                            });
                        }
                    }
                }}
            >
                <FormReset shouldClear={isNewItem} keepInitial />
                {actionButtonProps && (
                    <FormElementActionButton
                        {...actionButtonProps}
                        reload
                        disabled={isNewItem}
                        context={FormContext}
                    >
                        <Refresh />
                    </FormElementActionButton>
                )}
                <Grid container spacing={2} marginTop="1rem">
                    {showGraveLink && (
                        <Grid item xs={12}>
                            <Button variant="outlined" onClick={goToGrave} startIcon={<OpenInBrowserOutlined />}>
                                {`Grab: ${_.join([_.compact([grave.generalNr, _.join(_.compact([grave.division, grave.subDivision, grave.nr]), '/')])], ',')}`}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <FormElementAutocomplete
                            label="Verstorbener"
                            attribute="deceasedPersonId"
                            optionReference="deceasedPerson"
                            dataSchema={deceasedPersonDataSchema}
                            readOnly={!isNewItem}
                            routeId="peacebuddy_settings_deceasedPerson_route"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormElementAutocomplete
                            label="Sachbearbeiter"
                            attribute="clerkUserId"
                            optionReference="clerkUser"
                            dataSchema={userDataSchema}
                            routeId="beyondbuddy_settings_general_user_route"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormElementDatePicker label="Datum der Beisetzung" attribute="dateOfFuneral" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormElementDatePicker label="Ruhefrist Ende" attribute="burryDeadline" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementTextField label="Vermerk" attribute="notes" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementGraveRecordPositionChooser unitPositions={grave?.unitPositions} graveFormChanged={graveFormChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementEntityLinkAttributes
                            disabled={false}
                            attribute="customers"
                            getTableHead={getTableHead}
                            getAttributeLine={getAttributeLine}
                            availableEntities={availableEntities}
                            selfType="Customer"
                            entityChooserLabel="Auftraggeber hinzufügen"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementFilesUpload />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormElementLoadingButton label="Speichern" />
                    </Grid>
                </Grid>
            </FormWrapper>
        </ItemData>
    );
}

export {GraveRecordFormular, setGraveRecordId};
