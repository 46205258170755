import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {GraveFormular} from 'applications/peacebuddy/settings/forms/grave/GraveFormular';
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import {Exceptions as PeaceBuddyExceptions} from 'applications/peacebuddy/messages/Exceptions';
import {Warnings as PeaceBuddyWarnings} from 'applications/peacebuddy/messages/Warnings';

import {createGrave, updateGrave} from 'graphql/peaceBuddy/Grave/mutations';
import {getGrave} from 'graphql/peaceBuddy/Grave/queries';
import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {AuthorizedCustomersFormular} from 'applications/peacebuddy/settings/forms/grave/AuthorizedCustomersFormular';
import {GraveLinksFormular} from 'applications/peacebuddy/settings/forms/grave/GraveLinksFormular';
import {GraveRecordFormular} from 'applications/peacebuddy/settings/forms/grave/GraveRecordFormular';
import {GraveRecordsListFormular} from 'applications/peacebuddy/settings/forms/grave/GraveRecordsListFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {
    AddCircleOutline,
    ArrowLeftOutlined,
    ArticleOutlined,
    BlurOnOutlined, JoinInnerOutlined, ListOutlined, LockOutlined, OpenInBrowserOutlined, Person2Outlined,
} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {
    Box, Grid, Typography,
} from '@mui/material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {NotFound} from 'applications/pages/NotFound';
import {isValidGUID} from 'helper/guid';
import {useFindRoute} from 'hooks/useFindRoute';
import {useMessage} from 'hooks/useMessage';
import {MenuLink} from 'components/MenuLink';
import {ProtocolEntryListFormular} from 'applications/beyondbuddy/settings/forms/protocolEntry/ProtocolEntryListFormular';
import {ProtocolEntryFormular} from 'applications/beyondbuddy/settings/forms/protocolEntry/ProtocolEntryFormular';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {RouteLink} from 'components/RouteLink';
import {useGlobalState} from 'hooks/useGlobalState';

/** @type {Record<string, any>} */
const initialData = {
    units: 1,
    type: 'COFFIN',
    choosable: true,
    free: true,
};

const mask = {
    id: true,
    tenantId: true,
    cemeteryId: false,
    generalNr: true,
    type: true,
    division: false,
    subDivision: false,
    nr: false,
    lastFuneral: false,
    restPeriod: true,
    leaseStartDate: false,
    leaseExpirationDate: false,
    honoraryGrave: false,
    free: false,
    fellHome: false,
    choosable: false,
    unitPositions: true,
    notes: false,
    imageKey: false,
    attachmentKeys: false,
};

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the grave formular
 */
const getGraveFormular = (props) => <GraveFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the authorized customers formular
 */
const getAuthorizedCustomersFormular = (props) => (<AuthorizedCustomersFormular {...props} />);

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the grave links formular
 */
const getGraveLinksFormular = (props) => <GraveLinksFormular {...props} />;
/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`Grave#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#GraveForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            guideId: '',
            availablePermissions: createPermissionsObject(
                organizationEntityKeys,
                ['read', 'extendedReadPeaceBuddy', 'updateGroup', 'administrateGraveGroup'],
            ),
            defaultPermissions: createPermissionsObject(organizationEntityKeys, ['administrateGraveGroup']),
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

/**
 * This page shows a create and update form for a grave.
 * Also other information as authorized customers, links and permissions are available.
 * @returns {React.ReactElement} The CemeteryPage component.
 */
function GravePage() {
    const {id, subId} = useParams();
    const isNewItem = id === 'create';
    const {getGlobal} = useGlobalState();
    const userId = getGlobal('userId');
    const user = getGlobal('user');
    const navigate = useNavigate();
    const findRoute = useFindRoute();
    const [currentTab, setCurrentTab] = useState(0);
    const proRef = useRef();
    const canCreateGraveRecords = useCanAccess('createGraveRecord');
    const canCreateProtocolEntries = useCanAccess('createProtocolEntry');
    const {enqueueMessage} = useMessage();

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();
    const location = useLocation();

    const {hash} = location; // This will give you the hash (e.g., #section1)

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        ...!isNewItem ? {
            mutation: updateGrave,
            mask,
        } : {
            mutation: createGrave,
            mask: _.omit(mask, 'id'),
        },
        preProcess: (data) => {
            /** @type {import('applications/peacebuddy/types').GraveEntity} */
            const newData = data;
            if (_.some(_.flatMap(data.unitPositions), (position) => !position.label || !position.depth || !position.type)) {
                enqueueMessage(`grave_${id}_connections_tab`, PeaceBuddyExceptions.GRAVE_UNITPOSITION_MISSING_INFORMATION);
                return false;
            }
            const unitPositions = _.sumBy(data.unitPositions, _.size);

            if (newData.units < unitPositions) {
                enqueueMessage(`grave_${id}_connections_tab`, PeaceBuddyWarnings.GRAVE_UNITS_AUTOMATIC_SET);
                _.set(newData, 'units', unitPositions);
            }
            return {...newData, unitPositions: JSON.stringify(data.unitPositions)};
        },
        postProcess: (data) => ({...data, unitPositions: JSON.parse(data.unitPositions)}),
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getGrave,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: (data) => ({...data, unitPositions: JSON.parse(data.unitPositions)}),
    }), [id]);

    const getGraveRecordEntries = useCallback(() => (
        <>
            {(!subId && hash === '#graveRecord') && (
                <>
                    <Box display="flex" justifyContent="space-between">
                        {/* Span to avoid the actionbutton frame shifting to the right to take the buttons place */}
                        <span>
                            {canCreateGraveRecords && (
                                <FormElementActionButton
                                    dataTest="FormElementActionButton_Add"
                                    callback={() => navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_sub_route').path, {id, subId: 'create'})}#graveRecord`)}
                                    portalAnchorSelector={`#GraveForm${id}action-button-frame-graveRecord-actions`}
                                >
                                    <AddCircleOutline />
                                </FormElementActionButton>
                            )}
                        </span>
                    </Box>

                    <GraveRecordsListFormular
                        graveId={id}
                        actions={{
                            edit: {callBack: (item) => navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_sub_route').path, {id, subId: item.id})}#graveRecord`)},
                        }}
                        actionButtonProps={{portalAnchorSelector: `#GraveForm${id}action-button-frame-graveRecord-actions`}}
                        listDataRef={proRef}
                    />
                </>
            )}
            {(subId && hash === '#graveRecord') && (
                <>
                    <MenuLink
                        to={`/${generatePath(findRoute('peacebuddy_settings_grave_route').path, {id})}#graveRecord`}
                        onClick={() => {
                        // @ts-ignore
                            proRef.current?.reload?.();
                        }}
                    >
                        <Box display="flex" flexWrap="nowrap" alignContent="center">
                            <ArrowLeftOutlined color="primary" style={{marginRight: '2rem'}} />
                            <Typography color="primary" textTransform="uppercase">Zurück zur Übersicht</Typography>
                        </Box>
                    </MenuLink>

                    <GraveRecordFormular
                        id={subId}
                        initialData={{graveId: id}}
                        actionButtonProps={{portalAnchorSelector: `#GraveForm${id}action-button-frame-graveRecord-actions`}}
                        onSave={(result) => {
                            if (subId === 'create' && 'id' in result && result?.id) {
                                navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_sub_route').path, {id, subId: result?.id})}#graveRecord`);
                            }
                        }}
                    />
                </>
            )}
        </>
    ), [id, subId, hash, canCreateGraveRecords, proRef.current, navigate, generatePath, findRoute]);

    const initialDataProtocolEntry = useMemo(() => (subId === 'create' ? ({
        protocolDate: new Date().toISOString(),
        clerkUserId: userId,
        clerkUser: user,
    }) : {}), [subId, userId]);

    const getProtocolEntries = useCallback(() => (
        <>
            {(!subId && hash === '#protocolEntry') && (
                <>
                    <Box display="flex" justifyContent="space-between">
                        {/* Span to avoid the actionbutton frame shifting to the right to take the buttons place */}
                        <span>
                            {canCreateProtocolEntries && (
                                <FormElementActionButton
                                    dataTest="FormElementActionButton_Add"
                                    callback={
                                        () => navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_sub_route').path, {id, subId: 'create'})}#protocolEntry`)
                                    }
                                    portalAnchorSelector={`#GraveForm${id}action-button-frame-protocolEntry-actions`}
                                >
                                    <AddCircleOutline />
                                </FormElementActionButton>
                            )}
                        </span>
                    </Box>

                    <ProtocolEntryListFormular
                        entityTypeId={`Grave#${id}`}
                        actions={{
                            edit: {
                                callBack:
                                (item) => navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_sub_route').path, {id, subId: item.id})}#protocolEntry`),
                            },
                        }}
                        actionButtonProps={{portalAnchorSelector: `#GraveForm${id}action-button-frame-protocolEntry-actions`}}
                        listDataRef={proRef}
                    />
                </>
            )}
            {(subId && hash === '#protocolEntry') && (
                <>
                    <MenuLink
                        to={`/${generatePath(findRoute('peacebuddy_settings_grave_route').path, {id})}#protocolEntry`}
                        onClick={() => {
                        // @ts-ignore
                            proRef.current?.reload?.();
                        }}
                    >
                        <Box display="flex" flexWrap="nowrap" alignContent="center">
                            <ArrowLeftOutlined color="primary" style={{marginRight: '2rem'}} />
                            <Typography color="primary" textTransform="uppercase">Zurück zur Übersicht</Typography>
                        </Box>
                    </MenuLink>

                    <ProtocolEntryFormular
                        id={subId}
                        entityTypeId={`Grave#${id}`}
                        actionButtonProps={{portalAnchorSelector: `#GraveForm${id}action-button-frame-protocolEntry-actions`}}
                        onSave={(result) => {
                            if (subId === 'create' && 'id' in result && result?.id) {
                                navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_sub_route').path, {id, subId: result?.id})}#protocolEntry`);
                            }
                        }}
                        initialData={initialDataProtocolEntry}
                        additionalAttributesNode={(
                            <>
                                <Grid item xs={12} sm={6}>
                                    <FormElementContainer
                                        attribute="additionalAttributes"
                                        propsMapping={(props) => {
                                            const additionalAttributes = JSON.parse(props?.value ?? '{}');
                                            return {
                                                routeParams: {
                                                    id,
                                                    subId: additionalAttributes?.graveRecordId,
                                                },
                                                disabled: _.isNil(additionalAttributes?.graveRecordId),
                                            };
                                        }}
                                        conditionalRender={(props) => !props?.disabled}
                                    >
                                        <RouteLink
                                            routeId="peacebuddy_settings_grave_sub_route"
                                            hash="graveRecord"
                                            dataTest="FormElementAutocomplete_graveRecordId_RouteLink"
                                        >
                                            <Box display="flex" flexWrap="nowrap" alignContent="center">
                                                <OpenInBrowserOutlined color="primary" style={{marginRight: '2rem'}} />
                                                <Typography color="primary" textTransform="uppercase">Grabeintrag aufrufen</Typography>
                                            </Box>
                                        </RouteLink>
                                    </FormElementContainer>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormElementContainer
                                        attribute="additionalAttributes"
                                        propsMapping={(props) => {
                                            const additionalAttributes = JSON.parse(props?.value ?? '{}');

                                            return {
                                                routeParams: {id: additionalAttributes?.deceasedPersonId},
                                                disabled: _.isNil(additionalAttributes?.deceasedPersonId),
                                            };
                                        }}
                                        conditionalRender={(props) => !props?.disabled}
                                    >
                                        <RouteLink
                                            routeId="peacebuddy_settings_deceasedPerson_route"
                                            dataTest="FormElementAutocomplete_deceasedPersonsId_RouteLink"
                                        >
                                            <Box display="flex" flexWrap="nowrap" alignContent="center">
                                                <OpenInBrowserOutlined color="primary" style={{marginRight: '2rem'}} />
                                                <Typography color="primary" textTransform="uppercase">Verstorbene Person aufrufen</Typography>
                                            </Box>
                                        </RouteLink>
                                    </FormElementContainer>
                                </Grid>
                            </>
                        )}
                    />
                </>
            )}
        </>
    ), [id, subId, hash, canCreateProtocolEntries, initialDataProtocolEntry, proRef.current, navigate, generatePath, findRoute]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `GraveForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
                initialData,
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getGraveFormular({id, onSave}),
                formWrapperId: `Grave#${id}Base`,
                quickGuideId: 'peacebuddy_general_grave',
            }, {
                id: 'protocolEntry',
                label: 'Bewegungsdaten',
                icon: <ListOutlined />,
                enabledCondition: 'protocolEntryReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: () => !isNewItem && getProtocolEntries(),
                formWrapperId: `Grave#${id}peaceBuddyGraveProtocolEntries`,
                // quickGuideId: 'peacebuddy_general_grave_protocolEntries',
            },
            {
                id: 'authorized_customers',
                label: 'Nutzungsberechtigte',
                icon: <Person2Outlined />,
                enabledCondition: 'attributesReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: (grants) => !isNewItem && getAuthorizedCustomersFormular({id, readonly: !grants.attributesUpdatable}),
                formWrapperId: `Grave#${id}peaceBuddyGraveAuthorizedCustomers`,
                quickGuideId: 'peacebuddy_general_grave_authorized_customers',
            }, {
                id: 'graveRecord',
                label: 'Einträge',
                icon: <ArticleOutlined />,
                enabledCondition: 'graveRecordReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: () => !isNewItem && getGraveRecordEntries(),
                formWrapperId: `GraveRecord#${subId}Base`,
                quickGuideId: 'peacebuddy_general_graveRecord',
            }, {
                id: 'connections',
                label: 'Verknüpfungen',
                icon: <JoinInnerOutlined />,
                enabledCondition: 'attributesReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: (grants) => !isNewItem && getGraveLinksFormular({id, readonly: !grants.attributesUpdatable}),
                formWrapperId: `Grave#${id}peaceBuddyGraveConnections`,
                quickGuideId: 'peacebuddy_general_grave_connections',
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: (grants) => !isNewItem && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `Grave#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            ],
            currentTab,
            setCurrentTab,
            path: findRoute('peacebuddy_settings_grave_route') && generatePath(findRoute('peacebuddy_settings_grave_route').path, {id}),
        }), [id, isNewItem, loadConfig, saveConfig, initialData, currentTab,
            setCurrentTab, onSave, getAuthorizedCustomersFormular, getGraveRecordEntries, getGraveLinksFormular, getPermissionFormular, getGraveFormular,
            generatePath, findRoute,
        ]);

    // check if id is a guid and return notfound it is not
    if (!isValidGUID(id)) {
        return <NotFound />;
    }

    return (
        <LayoutContainer>
            <TabFormsContainer
                {...tabFormsContainerProps}
                breadCrumbs={[
                    {label: 'Gräber', routeId: 'peacebuddy_settings_graves_route'},
                ]}
            />
        </LayoutContainer>
    );
}
export {GravePage};
