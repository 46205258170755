import React, {
    forwardRef, useCallback, useContext, useImperativeHandle, useMemo,
} from 'react';
import _ from 'lodash';
import {ListDataContext} from 'components/Form/ListData';
import {ItemDataContext} from 'components/Form/ItemData';
import {useGlobalState} from 'hooks/useGlobalState';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Button, Tooltip, useMediaQuery, useTheme, CircularProgress, Skeleton, Typography,
} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {RouteLink} from 'components/RouteLink';

import classes from 'components/Form/listing.module.scss';
import {useRouteNavigate} from 'hooks/useRouteNavigation';
import {UnlockButton} from 'assets/theme/components/UnlockButton/UnlockButton';
import classNames from 'classnames';

import style from 'assets/theme/style.module.scss';

/**
 * @typedef {object} ListingSchemaColumn
 * @property {Array<ListingSchemaItem>} itemConfigurations - the items of the column
 * @property {import('@mui/material').BoxProps} [boxProps] - settings for the box item
 */

/**
 * An item of the listing (single cell)
 * @typedef {object} ListingSchemaItem
 * @property {string} title - title for showing the user as a tooltip
 * @property {(item: object) => React.ReactElement | string} renderItem - function to generate the element out of the list data
 * @property {import('@mui/material').BoxProps} [boxProps] - props for the box
 */

/**
 * @typedef {object} ListingSchemaAction
 * @property {React.ReactElement} icon - icon to show
 * @property {string} [routeId] - route id to navigate to
 * @property {(item: object) =>  boolean} [isLoading] - to that determines if the button should be in loading state
 * @property {(item: object) => boolean} [isVisible] - determines if the button should be shown at all
 * @property {(item: object)=>Record<string, string>} [routeParams] - route params to pass to the route
 * @property {(item: object)=>void} [callBack] - callback to call on click
 * @property {boolean} [safety] - if safety is needed, the button will have the unlock feature in case it doesn't have a routeid
 * @property {import('@mui/material').ButtonProps} [buttonProps] - props for the button
 */

/**
 * @typedef {object} ListingSchema
 * @property {Array<ListingSchemaColumn>} columns - configuration of all columns
 * @property {Array<ListingSchemaAction>} [actions] - actions to be visualized
 * @property {string} [routeId] - route id to navigate to
 * @property {(item: object)=>Record<string, string>} [routeParams] - route params that are passed to the route
 * @property {(item: object)=>void} [callBack] - callback that is called when the item is clicked
 * @property {(items: Array<object>, index: any, row: (item, index)=>React.ReactElement)=>React.ReactElement} [renderGroupRow] - function for rendering a row for grouping
 * @property {(data: any)=>any} [prepareData] - function for data preparation
 */

/**
 *
 * @param {ListingSchemaItem} configuration - item configuration of the column
 * @param {Record<string, string>} item - current item data
 * @param {string} index - index to set the key
 * @returns {React.ReactElement} The item element
 */
function generateItem(configuration, item, index) {
    return (
        <Box
            key={index}
            {...configuration.boxProps}
            style={{
                display: 'flex',
                gap: '0.5rem',
                ...configuration.boxProps?.style,
            }}
        >
            <Tooltip title={configuration.title} enterDelay={500} placement="top" key={index}>
                <>{configuration.renderItem(item)}</>
            </Tooltip>
        </Box>
    );
}

/**
 * @param {ListingSchemaColumn} configuration - schema column configuration
 * @param {Record<string, string>} item - current item data
 * @param {string} index - index to set the key
 * @returns {React.ReactElement} - a column element
 */
function generateColumn(configuration, item, index) {
    return (
        <Box
            data-test="column"
            key={index}
            {...configuration.boxProps}
            style={{
                width: '0px', // Typography increases the width of the parent element even if elipse occourse (text cropped with ...)
                overflow: 'hidden',
                alignItems: configuration.boxProps?.style?.alignItems ?? 'unset',
                flexDirection: 'column',
                justifyContent: 'center',
                ...configuration.boxProps?.style,
            }}
            sx={{
                ...configuration.boxProps?.sx,

                // @ts-ignore
                display: configuration.boxProps?.sx?.display ? configuration.boxProps?.sx?.display : 'flex',
            }}
            className={classes.column}
        >
            {_.map(configuration.itemConfigurations, (itemConfiguration, i) => generateItem(itemConfiguration, item, `${index}i${i}`))}
        </Box>
    );
}

/**
 *
 * @param {Array<ListingSchemaAction>} actions - action button configuration
 * @param {Record<string, string>} item - current item data
 * @returns {React.ReactElement} - actions element
 */
function generateActions(actions, item) {
    return (
        <>
            {_.map(actions, (action, index) => {
                const isLoading = () => (_.isFunction(action.isLoading) ? action.isLoading(item) : false);
                const isVisible = action.isVisible?.(item) ?? true;
                if (!isVisible) {
                    return (
                        <Button
                            sx={{visibility: 'hidden'}}
                            {...action.buttonProps}
                            variant="outlined"
                            key={index}
                            data-test="Listing_Button"
                        >
                            {action.icon}
                        </Button>
                    );
                }
                if (action.routeId) {
                    return (
                        <RouteLink key={index} routeId={action.routeId} routeParams={_.isFunction(action.routeParams) ? action.routeParams(item) : undefined}>
                            <Button variant="outlined" {...action.buttonProps} data-test="Listing_Button">{action.icon}</Button>
                        </RouteLink>
                    );
                }
                if (action.callBack) {
                    return (
                        <UnlockButton
                            variant="outlined"
                            key={index}
                            {...action.buttonProps}
                            onClick={() => { action.callBack(item); }}
                            data-test="Listing_Button"
                            bypass={!action.safety ?? true}
                        >
                            {isLoading() ? <CircularProgress size="1.5rem" /> : action.icon }
                        </UnlockButton>
                    );
                }
                return (
                    <Button
                        variant="outlined"
                        key={index}
                        {...action.buttonProps}
                        data-test="Listing_Button"
                    >
                        {action.icon}
                    </Button>
                );
            })}
        </>
    );
}

/**
 * The context is used to handle the state of the list form,
 * including the data and the loading state.
 */
const Listing = forwardRef(
    /**
     * The context is used to handle the state of the list form,
     * including the data and the loading state.
     * .Components.Form
     * @param {object} props - properties passed to the component.
     * @param {ListingSchema} props.schema - configuration how to render the items
     * @param {string} [props.attribute] - attribute key to get items from a ItemDataContext.
     * @param {boolean} [props.reverse] - indicates, that the data should be reversed
     * @param {string} [props.noEntries] - text to show, when no entries are present
     * @param {object} [ref] - reference of the component.
     * @returns {React.ReactElement} The list component with the ListData context.
     */
    ({
        schema, attribute, noEntries, reverse,
    }, ref) => {
    // for test, direct load edit context (should be a switch)
        const listContext = useContext(ListDataContext);
        const itemContext = useContext(ItemDataContext);

        // get data, depending on the provider
        const {isLoading, data} = attribute ? itemContext : listContext;
        const {addItem} = attribute ? {addItem: () => {}} : listContext;

        const routeNavigate = useRouteNavigate();

        const theme = useTheme();
        const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

        const {getGlobal} = useGlobalState();

        const preferredColorMode = useMediaQuery('(prefers-color-scheme: dark)');
        const userObj = getGlobal('user');
        const colorMode = useMemo(() => (userObj?.colorMode ?? (preferredColorMode ? 'dark' : 'light')), [preferredColorMode, userObj]);

        useImperativeHandle(ref, () => ({addItem}), [addItem]);

        const skeletonHeight = () => {
            const itemCount = Math.max(..._.map(schema.columns, (item) => item.itemConfigurations.length));
            if (itemCount > 1) return itemCount * 1.5 + 1;
            return itemCount * 1.5 + 2;
        };

        const prepareData = useMemo(() => {
            const dataItems = attribute ? data[attribute] : data;
            if (schema.prepareData) {
                return schema.prepareData(dataItems);
            }
            return dataItems;
        }, [data, attribute]);

        const row = useCallback((item, index) => {
            const clickHandler = () => {
                if (item.isDeleting) {
                    return; // do nothing if the item is marked to be deleted
                }
                if (_.isFunction(schema.callBack)) {
                    schema.callBack(item);
                }
                if (schema.routeId) {
                    routeNavigate(
                        schema.routeId,
                        _.isFunction(schema.routeParams) ? schema.routeParams(item) : undefined,
                    );
                }
            };

            const rowColumns = (
                <Box
                    key={index}
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexWrap: 'nowrap',
                        gap: '0.6rem',
                        overflow: 'hidden',
                        ...(schema.callBack || schema.routeId) ? {cursor: 'pointer'} : {},
                    }}
                    {...(schema.callBack || schema.routeId) ? {onClick: clickHandler} : {}}
                >
                    {_.map(schema.columns, (columnConfiguration, index2) => generateColumn(columnConfiguration, item, `${index}c${index2}`))}
                </Box>
            );
            const actions = generateActions(schema.actions, item);
            return (
                <Box
                    data-test="row"
                    className={classNames(classes.row, {
                        [classes.inDeletion]: item.inDeletion,
                    })}
                    sx={{':hover': {backgroundColor: {md: colorMode === 'dark' ? style.highlight_dark : style.highlight_light}}}}
                    key={item.id ?? index}
                >
                    {isDesktop && (
                        <Box style={{display: 'flex'}} sx={{paddingY: '0.5rem'}}>
                            {rowColumns}
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                            >
                                {actions}
                            </Box>
                        </Box>
                    )}
                    {!isDesktop && (
                        <Accordion
                            disableGutters
                            style={{
                                flexGrow: 1,
                                borderRadius: '0px !important',
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore className={classes.expandIcon} />}
                                className={classes.accordionSummary}
                                sx={{
                                    padding: '0px 1rem 0px 0px',
                                    justifyContent: 'flex-start',
                                    minHeight: '3.5rem',
                                    '.MuiAccordionSummary-content': {
                                        margin: '0px 1rem 0px 0px',
                                        overflow: 'hidden',
                                    },
                                }}
                            >
                                {rowColumns}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '2rem',
                                }}
                                >
                                    {actions}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
            );
        }, [isDesktop, classes, schema, routeNavigate, generateColumn, generateActions]);

        return (
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}} data-test="Listing">
                {
                    isLoading
                        ? (
                            _.times(3, (index) => (
                                <Box sx={{cursor: 'wait'}} className={classes.row} key={index}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width="100%"
                                        height={`${skeletonHeight()}rem`}
                                    />
                                </Box>
                            ))
                        )
                        : (
                            _.map(reverse ? _.reverse(prepareData) : prepareData, (item, index) => {
                                if (_.isArray(item)) {
                                    if (!schema.renderGroupRow) {
                                        return <Box key={index}>{_.map(item, row)}</Box>;
                                    }
                                    return schema.renderGroupRow(item, index, row);
                                }
                                return row(item, index);
                            })
                        )
                }
                {
                    (!isLoading && _.isEmpty(prepareData)) && (
                        <Box sx={{cursor: 'wait'}} className={classes.row}>
                            <Typography>{noEntries ?? 'Keine Einträge vorhanden'}</Typography>
                        </Box>
                    )
                }
            </Box>
        );
    },
);
Listing.displayName = 'Listing';

export {Listing};
