/* eslint-disable react/no-array-index-key */
import React, {
    useMemo, useContext, useCallback,
    useEffect,
} from 'react';
import {
    IconButton, TextField, Box, Grid,
    CircularProgress,
    Alert,
    CardContent,
    Card,
    CardActions,
    Autocomplete,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {v4 as uuidv4} from 'uuid'; // For unique IDs
import _ from 'lodash';
import classes from 'applications/peacebuddy/settings/forms/grave/FormElementGravePositions.module.scss';
import {
    ArrowCircleRightOutlined,
    ArrowCircleDownOutlined, OpenInBrowserOutlined,
} from '@mui/icons-material';
import {FormContext} from 'components/Form/FormWrapper';
import {generatePath, useNavigate} from 'react-router-dom';
import {useFindRoute} from 'hooks/useFindRoute';
import {graveUnitPositionTypeDataSchema} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';

const getDefaultDepth = (rowIndex) => (150 + 70 * (rowIndex + 1));

/**
 * FormElementGravePositions Component
 * @returns {React.ReactElement} FormElementGravePositions component
 */
export function FormElementGravePositions() {
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        isLoading, get, changeHandler, isReadonly,
    } = useContext(FormContext);

    const navigate = useNavigate();
    const findRoute = useFindRoute();

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     * TODO: Negative numbers are not allowed in the input field. This should be fixed.
     */
    const elementData = useMemo(() => get('unitPositions'), [get]);
    const matrix = useMemo(() => elementData?.value ?? [], [elementData?.value]);
    const choosable = useMemo(() => get('choosable').value ?? false, [get]);
    const graveId = useMemo(() => get('id').value, [get]);

    const matrixSize = useMemo(() => (matrix ? _.sumBy(matrix, _.size) : 0), [matrix]);

    const updateMatrix = useCallback((newMatrix) => {
        changeHandler({
            attribute: 'unitPositions',
            value: newMatrix,
            error: elementData?.error,
            interacted: true,
        });
        // setMatrix(newMatrix);
    }, [elementData, changeHandler]);

    /**
     * Checks specific conditions for an element in a matrix.
     * @param {'right'|'below'} direction - the direction of the add button
     * @param {number} rowIndex - The index of the row to check.
     * @param {number} colIndex - The index of the column to check.
     * @returns {boolean} Returns `true` if the conditions are met, otherwise `false`.
     *
     * Conditions:
     * 1. It is the first row and it is the last item in the row.
     * 2. It is not the first row, it is the last item in the row, and the row above has at least one more item than the current column index.
     */
    const showAddButtonConditions = useCallback((direction, rowIndex, colIndex) => {
        if (isReadonly) {
            return false;
        }

        if (direction === 'right') {
            // Check if it's the first row and the last item in that row
            if (rowIndex === 0 && colIndex === _.size(matrix[rowIndex]) - 1) {
                return true;
            }

            // Check if it's not the first row, the last item in that row,
            // and the row above has more items than the current colIndex
            if (rowIndex > 0 && colIndex === _.size(matrix[rowIndex]) - 1 && colIndex < _.size(matrix[rowIndex - 1]) - 1) {
                return true;
            }
        } else {
            return (colIndex === 0 && !matrix[rowIndex + 1]) || (matrix[rowIndex + 1] && colIndex === matrix[rowIndex + 1].length);
        }

        return false;
    }, [matrixSize, matrix, isReadonly]);

    /**
     * Checks if an item in the matrix is deletable.
     * @param {number} rowIndex - The index of the row where the item is located.
     * @param {number} colIndex - The index of the column where the item is located.
     * @returns {boolean} Returns `true` if the item is deletable, otherwise `false`.
     */
    const isItemDeletable = useCallback((rowIndex, colIndex) => {
        const currentItem = matrix[rowIndex][colIndex];

        // Check if the current item has a graveRecordId
        if (currentItem.graveRecordId) {
            return false;
        }

        // Check all items to the right in the same row and below each of those items
        for (let i = colIndex; i < matrix[rowIndex].length; i += 1) {
            if (matrix[rowIndex][i].graveRecordId) {
                return false;
            }

            // Check all items below the current item to the right
            for (let j = rowIndex + 1; j < matrix.length; j += 1) {
                if (matrix[j][i] && matrix[j][i].graveRecordId) {
                    return false;
                }
            }
        }

        // Check all items below the current item in the same column
        for (let j = rowIndex + 1; j < matrix.length; j += 1) {
            if (matrix[j][colIndex] && matrix[j][colIndex].graveRecordId) {
                return false;
            }
        }

        return true;
    }, [matrix]);

    const handleAddElement = useCallback((id, direction) => {
        // Clone the matrix for modification
        /** @type {Array<import('applications/peacebuddy/types').GraveUnitPosition & {id: string}>[]} */
        const newMatrix = matrix?.map((row) => row.map((el) => ({...el})));

        // Find the position of the element to be added
        const rowIndex = newMatrix.findIndex((row) => row.find((el) => el.id === id) !== undefined);
        const colIndex = newMatrix[rowIndex].findIndex((el) => el.id === id);

        if (direction === 'right') {
            // Add element to the right
            if (colIndex >= newMatrix[rowIndex].length - 1) {
                // Add element to the end of the row if it's the last column
                newMatrix[rowIndex].push({
                    id: uuidv4(),
                    depth: getDefaultDepth(rowIndex),
                    label: `P${rowIndex + 1}/${colIndex + 2}`,
                    type: 'COFFIN',
                });
            } else {
                // Insert element to the right of the existing element
                newMatrix[rowIndex].splice(colIndex + 1, 0, {
                    id: uuidv4(),
                    depth: getDefaultDepth(rowIndex),
                    label: `P${rowIndex + 1}/${colIndex + 2}`,
                    type: 'COFFIN',
                });
            }
        } else if (direction === 'below') {
            // Add element below
            const nextRow = rowIndex + 1;
            if (nextRow >= newMatrix.length) {
                // Create a new row without pre-filling it with elements
                newMatrix.push([]);
            }

            newMatrix[nextRow][colIndex] = {
                id: uuidv4(),
                depth: getDefaultDepth(rowIndex + 1),
                label: `P${nextRow + 1}/${colIndex + 1}`,
                type: 'COFFIN',
            };
        }

        updateMatrix(newMatrix);
    }, [matrix, getDefaultDepth, updateMatrix]);

    const handleRemoveElement = useCallback((id) => {
        // Find index of the element to remove
        const rowIndex = matrix.findIndex((row) => row.find((el) => el.id === id) !== undefined);
        const colIndex = matrix[rowIndex].findIndex((el) => el.id === id);

        let newMatrix = matrix?.map((row) => row.map((el) => ({...el})));

        // Validate the row and column indices
        if (rowIndex >= newMatrix.length || colIndex >= newMatrix[rowIndex].length) {
            throw new Error('Row or column out of bounds.');
        }

        // Remove the item and all items to the right in the specified row
        newMatrix[rowIndex] = _.slice(newMatrix[rowIndex], 0, colIndex);

        // Adjust rows below to ensure no row has more items than the row above
        for (let r = rowIndex + 1; r < newMatrix.length; r += 1) {
            if (newMatrix[r].length > newMatrix[r - 1].length) {
                newMatrix[r] = _.slice(newMatrix[r], 0, newMatrix[r - 1].length);
            }
        }

        // Remove any empty rows
        newMatrix = _.filter(newMatrix, (row) => row.length > 0);

        updateMatrix(newMatrix);
    }, [matrix, updateMatrix]);

    const handleInputChange = useCallback((id, value, field) => {
        const newMatrix = matrix?.map((row) => row.map((el) => (el.id === id ? {...el, [field]: value} : el)));
        updateMatrix(newMatrix);
    }, [matrix, updateMatrix]);

    const addFirstElement = useCallback(() => {
        updateMatrix([[{
            id: uuidv4(), depth: getDefaultDepth(0), label: 'P1/1',
        }]]);
    }, [updateMatrix, getDefaultDepth]);

    useEffect(() => {
        if (!matrixSize) {
            addFirstElement();
        }
    }, [matrixSize, addFirstElement]);

    const getPositionContent = useCallback((el, rowIndex, colIndex) => {
        const disabled = (isLoading.load || isLoading.save);
        return (
            <>
                <CardContent className={classes['card-content']}>
                    <TextField
                        data-test="FormElementGravePosition_label"
                        label="Bezeichnung"
                        value={el.label}
                        error={!el.label}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleInputChange(el.id, e.target.value, 'label')}
                        disabled={disabled || isReadonly}
                        fullWidth
                    />
                    <TextField
                        data-test="FormElementGravePosition_depth"
                        label="Tiefe (cm)"
                        type="number"
                        value={el.depth}
                        error={!el.depth}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleInputChange(el.id, e.target.value, 'depth')}
                        disabled={disabled || isReadonly}
                        fullWidth
                    />
                    <Autocomplete
                        data-test="FormElementGravePosition_type"
                        data-options={graveUnitPositionTypeDataSchema.options.length}
                        options={graveUnitPositionTypeDataSchema.options}
                        value={_.find(graveUnitPositionTypeDataSchema.options, (o) => o.value === el?.type) ?? null}
                        disabled={disabled || isReadonly || !!(el.type && el.graveRecordId)}
                        renderInput={(params) => (
                            <TextField
                                label="Art"
                                error={!el.type}
                                {...params}
                            />
                        )}
                        onChange={(event, newValue) => {
                            handleInputChange(el.id, newValue?.value, 'type');
                        }}
                        getOptionLabel={(option) => option?.de}
                    />
                </CardContent>
                {!isReadonly && (
                    <CardActions className={classes['card-actions']}>
                        <IconButton
                            data-test="FormElementGravePosition_delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveElement(el.id);
                            }}
                            disabled={disabled || !isItemDeletable(rowIndex, colIndex)}
                        >
                            <DeleteIcon />
                        </IconButton>
                        <IconButton
                            data-test="FormElementGravePosition_show"
                            onClick={() => {
                                // eslint-disable-next-line max-len
                                navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_sub_route').path, {id: graveId, subId: el?.graveRecordId})}#graveRecord`);
                            }}
                            disabled={disabled || !el.graveRecordId}
                        >
                            <OpenInBrowserOutlined />
                        </IconButton>
                        <IconButton
                            data-test="FormElementGravePosition_addBelow"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleAddElement(el.id, 'below');
                            }}
                            disabled={disabled || !showAddButtonConditions('below', rowIndex, colIndex)}
                        >
                            <ArrowCircleDownOutlined />
                        </IconButton>
                        <IconButton
                            data-test="FormElementGravePosition_addRight"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleAddElement(el.id, 'right');
                            }}
                            disabled={disabled || !showAddButtonConditions('right', rowIndex, colIndex)}
                        >
                            <ArrowCircleRightOutlined />
                        </IconButton>
                    </CardActions>
                )}
            </>
        );
    }, [classes, isReadonly, isLoading.load, isLoading.save, showAddButtonConditions, handleInputChange, handleAddElement, navigate, generatePath, findRoute]);

    if (!matrixSize && (isLoading.load || isLoading.save)) {
        return (
            <Box className={classes['grave-container']}>
                <Grid container>
                    <Grid item>
                        <Box className={classes['grave-element']}>
                            <CircularProgress />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Card variant="outlined">
            <CardContent className={classes['grave-container']}>

                {(!choosable && matrixSize > 1)
            && (
                <Alert data-test="FormElementGravePosition_too_less_units_warning" severity="warning">
                Ein Reihengrab sollte nicht mehr als einen Platz aufweisen. Ändere es ggf. zu einem Wahlgrab
                </Alert>
            )}
                {matrix?.map((row, rowIndex) => (
                    <Grid container key={`row-${rowIndex}`} spacing={2} wrap="nowrap">
                        {row.map((el, colIndex) => (el ? (
                            <Grid item key={el.id}>
                                <Card
                                    variant="outlined"
                                    data-test={`FormElementGravePosition_${rowIndex}/${colIndex}`}
                                    data-used={!!el.graveRecordId}
                                    className={`${classes['grave-element']} ${el.graveRecordId ? classes.used : ''}`}
                                >
                                    <CardContent className={classes['card-container']}>
                                        {getPositionContent(el, rowIndex, colIndex)}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ) : null))}
                    </Grid>
                ))}
            </CardContent>
        </Card>
    );
}
